import * as React from 'react';
import { Alert } from "antd";

export class PrivacyPolicy extends React.Component {

    /**
     * Renders the component.
     *
     * @returns {any}
     */
    public render() {
        return (
            <div style={{ fontSize: '16px' }}>
                <Alert
                    message={"Politikiwi has been retired"}
                    description={"This project is no longer actively maintained."}
                    type={"warning"}
                    showIcon={true}
                />
                <br/>
                <b style={{ fontSize: '24px' }}>Privacy Policy</b><br />
                This privacy policy will explain what kind of data is used and collected by Politikiwi.
                This information will enable you to make an educated decision about using our website.<br />
                <br />
                This policy was last updated on 15 April 2023.<br />
                <br />
                <b style={{ fontSize: '20px' }}>Data Collection and Usage</b><br />
                Politikiwi only gathers data which is classed as statistical information.
                It does not gather personally identifiable information.<br />
                <br />
                When a website visitor takes our political values test, we collect and save to a database:<br />
                — Their overall result on each axis the test measures.<br />
                — Their answers to each individual statement presented to them.<br />
                <br />
                This data is collected and saved so you can share your results with others using a link provided when you finish the test.<br />
                <br />
                An older version of the test asked for demographic and voting intention details, but as of March 2020 it no longer collects this information.<br />
                <br />
                Politikiwi used to collect and save tweets via Twitter’s official API in compliance with Twitter's terms of use.<br />
                <br />
                These tweets were collected and saved as part of the Party Leader Popularity function, which has since been disabled.<br/>
                <br/>
                Politikiwi uses Google Analytics to observe the behaviour of visitors to the website.<br />
            </div>
        );
    }
}