import * as React from "react";
import * as Types from "../../model/Types";
import {Col, Popover, Row} from "antd";
import {PartyDetails} from "../general/PartyDetails";

interface PartyVoteResultProps {
    result: Types.PartyVotePollResult;
    renderLine: boolean;
    seats: boolean;
}

export class PartyVoteResult extends React.Component<PartyVoteResultProps> {

    constructor(props: PartyVoteResultProps) {
        super(props);
    }

    /**
     * Returns the difference in party vote between this result and the party's result at the last election.
     *
     * @returns {string}
     */
    public getPartyVoteChange(): string {
        const change: number = this.props.result.result - this.props.result.party.lastElectionResult;
        return (change > 0 ? "+" : "") + Number(change).toFixed(1) + "%";
    }

    /**
     * Returns the difference in seats between this result and their current seat count.
     *
     * @returns {string}
     */
    public getSeatCountChange(): string {
        const change: number = this.props.result.parliamentarySeats - this.props.result.party.currentSeats;
        return (change > 0 ? "+" : "") + change;
    }

    /**
     * Renders this component.
     *
     * @returns {any}
     */
    public render() {
        const result: Types.PartyVotePollResult = this.props.result;
        const color: string = result.party.colour;
        const flexCenteredStyling = {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        };

        return (
            <Row style={this.props.renderLine ? {
                ...flexCenteredStyling,
                borderBottom: '2px solid rgba(0, 0, 0, 0.25)'
            } : flexCenteredStyling}>
                <Col span={6} style={flexCenteredStyling}>
                    <b style={{ fontSize: '45px', color }}>
                        {this.props.seats ? result.parliamentarySeats : result.result.toFixed(1)}
                    </b>
                </Col>

                <Popover
                    title={result.party.name}
                    content={<PartyDetails party={result.party} />}
                    placement={"bottom"}
                >
                    <Col span={12} style={flexCenteredStyling}>
                        <span style={{ fontSize: '25px', color }}>
                            {result.party.friendlyName}
                        </span>
                    </Col>
                </Popover>

                <Col span={6} style={flexCenteredStyling}>
                    <b style={{ fontSize: '25px', color }}>
                        {this.props.seats ? this.getSeatCountChange() : this.getPartyVoteChange()}
                    </b>
                </Col>
            </Row>
        );
    }
}