import * as React from "react";
import * as Types from "../../../model/Types";
import * as TestConstants from "../../../model/test/TestConstants";
import {Alert, Icon, Select} from "antd";
import "../../../style/test/results/ResultCompare.scss";

interface ResultCompareProps {
    result: Types.TestResult;
    demographic: Types.DemographicComparison;
    partyVote: Types.PartyComparison | undefined;
    intention: Types.PartyComparison | undefined;
}

interface ResultCompareState {
    comparisonMethod: string;
}

export class ResultCompare extends React.Component<ResultCompareProps, ResultCompareState> {

    constructor(props: ResultCompareProps) {
        super(props);

        this.state = {
            comparisonMethod: "demographic"
        };

        this.onComparisonMethodChange = this.onComparisonMethodChange.bind(this);
    }

    /**
     * Returns the average axis score on the currently selected comparison object.
     *
     * @param {string} axis - the name of the axis to get.
     *
     * @returns {number}
     */
    private getProperty(axis: string): number {
        if (this.state.comparisonMethod === "partyVote" && this.props.partyVote !== undefined) {
            return this.props.partyVote[axis];
        } else if (this.state.comparisonMethod === "intention" && this.props.intention !== undefined) {
            return this.props.intention[axis];
        } else {
            return this.props.demographic[axis];
        }
    }

    /**
     * Returns whether or not the current comparison is based on a small sample.
     *
     * @returns {boolean}
     */
    private isSmallSample(): boolean {
        if (this.state.comparisonMethod === "partyVote" && this.props.partyVote !== undefined) {
            return this.props.partyVote.sampleSize < 20;
        } else if (this.state.comparisonMethod === "intention" && this.props.intention !== undefined) {
            return this.props.intention.sampleSize < 20;
        } else {
            return this.props.demographic.sampleSize < 50;
        }
    }

    /**
     * Updates the comparison method in the state when changed.
     *
     * @param {string} value - the option selected.
     *
     * @returns void
     */
    private onComparisonMethodChange(value: string) {
        this.setState({
            comparisonMethod: value
        });
    }

    /**
     * Renders the dropdown to select which group of respondents to compare against.
     *
     * @returns {JSX.Element}
     */
    private renderComparisonMethodDropdown(): JSX.Element {
        return (
            <Select
                size={"large"}
                defaultValue={"demographic"}
                value={this.state.comparisonMethod}
                style={{ width: '75%' }}
                onChange={this.onComparisonMethodChange}
            >
                <Select.Option value={"demographic"}>
                    {this.props.demographic.gender}, {this.props.demographic.generation}
                </Select.Option>

                {this.props.partyVote && <Select.Option value={"partyVote"}>
                    {this.props.partyVote.partyName} Voters
                </Select.Option>}

                {this.props.intention && <Select.Option value={"intention"}>
                    {this.props.intention.partyName} Supporters
                </Select.Option>}
            </Select>
        );
    }

    /**
     * Renders the comparison for an axis.
     *
     * @param {string} axis - the axis comparison to render.
     *
     * @returns {JSX.Element}
     */
    private renderAxisComparison(axis: string): JSX.Element {
        const icons = TestConstants.ICONS[axis];
        const iconStyle = { fontSize: '40px', marginRight: '10px' };
        const diff: number = ((this.props.result[axis] - this.getProperty(axis)) * 100);

        return (
            <div className={"axisComparison"}>
                <Icon type={icons.left} style={iconStyle} />
                <Icon type={icons.right} style={iconStyle} />

                <span className={"axisComparisonText"}>
                    {this.getRelevantDescriptor(diff, this.props.result[axis], axis)}
                </span>
            </div>
        );
    }

    /**
     * Returns the relevant description of the differences between this result and the average.
     *
     * @param {number} diff - the difference between scores on this axis.
     * @param {number} result - the axis score of the respondent.
     * @param {string} axis - the name of the axis being compared.
     *
     * @returns {string}
     */
    private getRelevantDescriptor(diff: number, result: number, axis: string): string
    {
        const descriptors = TestConstants.COMPARISON_DESCRIPTORS[axis];
        if (diff === 0) {
            return descriptors.same;
        }

        const rounded: string = Math.abs(diff).toFixed(0);
        let descriptor: string = descriptors.highMore;
        if (diff > 0) {
            if (result >= 0.5) {
                descriptor = descriptors.highMore;
            } else {
                descriptor = descriptors.lowLess;
            }
        } else {
            if (result >= 0.5) {
                descriptor = descriptors.highLess;
            } else {
                descriptor = descriptors.lowMore;
            }
        }

        return rounded + "% " + descriptor;
    }

    /**
     * Returns the text suffix for each axis comparison based on the selected method.
     *
     * @returns {string}
     */
    private getComparisonTextSuffix(): string {
        if (this.state.comparisonMethod === "partyVote") {
            return "respondents that voted like I did in 2017"
        } else if (this.state.comparisonMethod === "intention") {
            return "respondents who intend to vote like I do in 2020"
        } else {
            return "respondents like me"
        }
    }

    /**
     * Renders the component.
     *
     * @returns {any}
     */
    public render() {
        return (
            <div>
				<span className={"header"}>How does my result compare to {this.getComparisonTextSuffix()}?</span>

                <div className={"centeredContent dropdown"}>
                    {this.renderComparisonMethodDropdown()}
                </div>

                {this.renderAxisComparison("social")}
                {this.renderAxisComparison("economic")}
                {this.renderAxisComparison("nation")}
                {this.renderAxisComparison("state")}

                {this.isSmallSample() && <Alert
                    message="Small Sample Size"
                    description="This comparison is based off a small sample of respondents."
                    type="warning"
                    style={{ marginTop: '15px' }}
                    showIcon={true}
                />}
            </div>
        );
    }
}