import * as React from 'react';
import * as Types from "./model/Types";
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {Home} from './pages/Home';
import {Polling} from './pages/Polling';
import './App.css';
import {PoliticalValuesTest} from "./pages/PoliticalValuesTest";
import {PrivacyPolicy} from "./pages/PrivacyPolicy";
import {ValuesTestBase} from "./components/test/ValuesTestBase";
import {NavigationMenu} from "./components/menu/NavigationMenu";
import {About} from "./pages/About";
import {SeatCalculator} from "./pages/SeatCalculator";

class App extends React.Component {

    /**
     * Returns a list of politikiwi's pages for display on the menu.
     *
     * @returns {Page[]}
     */
    public getPages(): Types.Page[] {
        return [
            {
                path: "polling",
                icon: "line-chart",
                name: "Opinion Polling",
            },
            {
                path: "values",
                icon: "align-left",
                name: "Measure Your Values",
            },
            /*
            {
                path: "popularity",
                icon: "usergroup-add",
                name: "Party Leader Popularity",
            },
            {
                path: "tracker",
                icon: "unordered-list",
                name: "Coalition Tracker",
            },
            */
            {
                path: "calculator",
                icon: "calculator",
                name: "Seat Calculator",
            },
            {
                path: "privacy",
                icon: "unordered-list",
                name: "Privacy",
            },
            {
                path: "about",
                icon: "user",
                name: "About",
            }
        ];
    }

    /**
     * Renders the politikiwi application.
     *
     * @returns {any}
     */
    public render() {
        return (
            <Router>
                <div style={{ fontFamily: 'Source Sans Pro', overflowX: 'hidden' }}>
                    <NavigationMenu pages={this.getPages()} />

                    <div style={{ margin: '25px' }}>
                        <Switch>
                            <Route path="/" component={Home} exact={true} />
                            <Route path="/polling" component={Polling} />
                            { /* <Route path="/popularity" component={PartyLeaderPopularity} /> */ }
                            <Route path="/values" component={PoliticalValuesTest} exact={true} />
                            <Route path="/values/:identifier" exact={true} render={props => {
                                return <ValuesTestBase identifier={props.match.params.identifier} />
                            }} />
                            { /* <Route path="/tracker" component={CoalitionTracker} /> */ }
                            <Route path="/privacy" component={PrivacyPolicy} />
                            <Route path="/about" component={About} />
                            <Route path="/calculator" component={SeatCalculator} />
                        </Switch>
                    </div>
                </div>
            </Router>
        );
    }
}

export default App;
