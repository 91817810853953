import * as React from "react";
import * as Types from "../../model/Types";
import * as Api from "../../model/Api";
import * as Utilities from "../../model/Utilities";
import * as Constants from "../../model/test/TestConstants";
import {ValuesTestStart} from "./ValuesTestStart";
import {Error} from "../general/Error";
import {ValuesTestQuestion} from "./ValuesTestQuestion";
import {ValuesTestResults} from "./ValuesTestResults";
import {ExistingResultsWrapper} from "./results/ExistingResultsWrapper";

interface ValuesTestBaseProps {
    identifier: string | undefined;
}

interface ValuesTestBaseState {
    complete: boolean;
    questions: Types.PoliticalQuestion[];
    questionsAnswered: number;
    answers: Types.QuestionAnswer[];

    social: number;
    economic: number;
    nation: number;
    state: number;

    errors: string[];
}

export class ValuesTestBase extends React.Component<ValuesTestBaseProps, ValuesTestBaseState> {

    constructor(props: ValuesTestBaseProps) {
        super(props);

        this.state = {
            complete: false,
            questions: [],
            questionsAnswered: 0,
            answers: [],
            social: 0.50,
            economic: 0.50,
            nation: 0.50,
            state: 0.50,
            errors: [],
        };

        this.startTest = this.startTest.bind(this);
        this.onResponse = this.onResponse.bind(this);
    }

    /**
     * Starts the test by fetching and then randomly shuffling all questions.
     *
     * @returns void
     */
    public startTest() {
        Api.get("politicalquestion.php", response => {
            const questions: Types.PoliticalQuestion[] = response as Types.PoliticalQuestion[];
            const shuffled: Types.PoliticalQuestion[] = Utilities.shuffle(questions);

            this.setState({ questions: shuffled });
        }, errors => this.setState({ errors }));
    }

    /**
     * Returns the question currently being asked.
     *
     * @returns {PoliticalQuestion | undefined}
     */
    public getCurrentQuestion(): Types.PoliticalQuestion | undefined {
        return this.state.questions[this.state.questionsAnswered];
    }

    /**
     * Handles the response to the current question.
     *
     * @param {number} weight - the value to multiply the question's axis modifiers by.
     *
     * @returns void
     */
    public onResponse(weight: number) {
        const question = this.getCurrentQuestion();
        if (question !== undefined) {
            this.setState({ questionsAnswered: this.state.questionsAnswered + 1 });

            // update the value for each political axis property in the state.
            Constants.POLITICAL_AXES.forEach((axis: string) => {
                // we have to cast this to any to use dynamic object keys for top-level state properties.
                this.setState({
                    [axis]: this.state[axis] + (question[axis] * weight),
                    answers: [...this.state.answers, {
                        questionId: question.id, weight
                    }]
                } as any);
            });

            // we still need to add one to this as setState is asynchronous.
            if ((this.state.questionsAnswered + 1) === Constants.QUESTION_COUNT) {
                this.setState({ complete: true });
            }
        }
    }

    /**
     * Renders this component.
     *
     * @returns {any}
     */
    public render() {
        if (this.props.identifier !== undefined) {
            return <ExistingResultsWrapper identifier={this.props.identifier} />
        }

        const errors = this.state.errors;
        if (errors.length > 0) {
            return <Error key={errors.length} error={errors.join("\n")} />
        }

        if (this.state.complete) {
            return <ValuesTestResults
                social={this.state.social}
                economic={this.state.economic}
                nation={this.state.nation}
                state={this.state.state}
                answers={this.state.answers}
                fromLink={false}
                identifier={undefined}
            />
        }

        const currentQuestion = this.getCurrentQuestion();
        if (currentQuestion !== undefined) {
            return <ValuesTestQuestion
                question={currentQuestion}
                questionNumber={this.state.questionsAnswered + 1}
                onResponse={this.onResponse}
            />
        } else {
            return <ValuesTestStart onStart={this.startTest} />
        }
    }
}