import * as React from "react";
import { Alert, Col, Icon, Row } from "antd";

interface PoliticalAxisDetails {
    text: string;
    subText: string;
    icon: string;
}

export class ValuesTestInfo extends React.Component {

    /**
     * Returns details about of each the measured political axes.
     *
     * @returns {PoliticalAxisDetails[]}
     */
    public getPoliticalAxesDetails(): PoliticalAxisDetails[] {
        return [
            {
                text: "Social Axis",
                subText: "Progressive vs Conservative",
                icon: "team",
            },
            {
                text: "Economic Axis",
                subText: "Capitalist vs Socialist",
                icon: "dollar",
            },
            {
                text: "Nation Axis",
                subText: "Internationalist vs Nationalist",
                icon: "flag",
            },
            {
                text: "State Axis",
                subText: "Libertarian vs Authoritarian",
                icon: "bank",
            }
        ]
    }

    /**
     * Renders this component.
     *
     * @returns {any}
     */
    public render() {
        return (
            <div style={{ fontSize: '16px' }}>
                You will be prompted with a political statement and asked if you agree or disagree with the statement.
                Your response will slightly adjust your score on our four political axes. The statements are designed to
                figure out where you stand on specific issues, as well as the fundamental ideology which forms your views.
                Some statements reflect more balanced or centrist positions, and will have little impact on your score,
                whereas some statements present a more radical view, and agreement with the statement will adjust your score
                significantly. Upon completion of the test, your score for each axis will be compared to the maximum, and
                when combined will give an indication of your political philosophy.

                <br /><br />

                These are the four political axes the test measures:

                <div style={{ margin: '15px 0px 15px 0px' }}>
                    {this.getPoliticalAxesDetails().map((axis: PoliticalAxisDetails) => {
                        return (
                            <Row key={axis.icon}>
                                <Col span={4}>
                                    <Icon type={axis.icon} style={{ fontSize: '24px' }} />
                                </Col>
                                <Col span={20}>
                                    <b>{axis.text}</b> ({axis.subText})
                                </Col>
                            </Row>
                        );
                    })}
                </div>

                <Alert
                    message={"Privacy Notice"}
                    description={"Please note that this test collects and saves some data. How you answer each question and your final results are saved in a database alongside a randomly generated ID. This data is saved so you can share your test results with others. The test does not ask for personally identifiable information, so your test results cannot be linked to you personally unless you choose to share them."}
                    type={"info"}
                />
            </div>
        );
    }
}