import * as React from "react";
import * as Types from "../../../model/Types";
import * as Api from "../../../model/Api";
import {ALIGNMENTS} from "../../../model/test/PartyAlignments";
import {Error} from "../../general/Error";
import {Row, Col, Popover, Icon} from 'antd';
import {PartyDetails} from "../../general/PartyDetails";

interface MatchingPartiesResultProps {
    social: number;
    economic: number;
    nation: number;
    state: number;
}

interface MatchingPartiesResultState {
    parties: Types.Party[];
    errors: string[];
}

interface MatchingParty {
    party: Types.Party;
    ideologies: string;
    match: number;
}

export class MatchingPartiesResult extends React.Component<MatchingPartiesResultProps, MatchingPartiesResultState> {

    constructor(props: MatchingPartiesResultProps) {
        super(props);

        this.state = {
            parties: [],
            errors: [],
        };
    }

    /**
     * Fetches parties from the backend and loads them into the state.
     *
     * @returns void
     */
    public componentDidMount() {
        Api.get("party.php", response => {
            this.setState({
                parties: response as Types.Party[]
            });
        }, errors => this.setState({ errors }));
    }

    /**
     * Returns a list of parties in order of how they align with these results.
     *
     * @returns {MatchingParty[]}
     */
    public getMatchingParties(): MatchingParty[] {
        return this.state.parties
            .map((party: Types.Party) => {
                const alignment = ALIGNMENTS.find(a => a.code === party.code);

                let dist: number = 0;
                dist += Math.abs(alignment.axes.social - this.props.social);
                dist += Math.abs(alignment.axes.economic - this.props.economic);
                dist += Math.abs(alignment.axes.nation - this.props.nation);
                dist += Math.abs(alignment.axes.state - this.props.state);

                return {
                    party,
                    ideologies: alignment.ideologies,
                    match: (4.0 - dist) / 4,
                }
            }).sort((a, b) => a.match > b.match ? -1 : 1);
    }

    /**
     * Returns the hex colour code appropriate for the given percentage ideological match.
     *
     * @param {number} match - the match value between 0.0 and 1.0.
     *
     * @returns {string}
     */
    public getMatchColour(match: number) {
        if (match > 0.9) {
            return "#135200";
        } else if (match > 0.8) {
            return "#237804";
        } else if (match > 0.7) {
            return "#389e0d";
        } else if (match > 0.6) {
            return "#faad14";
        }

        return "#d4380d";
    }

    /**
     * Renders the line for the given ideologically matching party.
     *
     * @param {MatchingParty} matchingParty - the matching party.
     *
     * @returns {JSX.Element}
     */
    public renderMatchingParty(matchingParty: MatchingParty): JSX.Element {
        return (
            <Row style={{ margin: '10px 0px 10px 0px' }}>
                <Col span={6} style={{ fontWeight: 'bold', color: matchingParty.party.colour }}>
                    <Popover
                        title={matchingParty.party.name}
                        content={<PartyDetails party={matchingParty.party} />}
                        placement={"left"}
                    >
                        {matchingParty.party.name}
                    </Popover>
                </Col>
                <Col span={12}>
                    {matchingParty.ideologies}
                </Col>
                <Col span={6} style={{ fontWeight: 'bold', color: this.getMatchColour(matchingParty.match) }}>
                    {(matchingParty.match * 100).toFixed(1)}% Match
                </Col>
            </Row>
        )
    }

    /**
     * Renders the component.
     *
     * @returns {any}
     */
    public render() {
        const errors = this.state.errors;
        if (errors.length > 0) {
            return <Error key={errors.length} error={errors.join("\n")} />
        }

        if (this.state.parties.length > 0) {
            const matchingParties: MatchingParty[] = this.getMatchingParties();
            return (
                <div style={{
                    display: 'block',
                    width: '100%',
                    fontSize: '20px',
                    lineHeight: 1.1,
                    textAlign: 'center',
                    marginTop: '25px'
                }}>
                    {matchingParties.slice(0, 3).map((matchingParty: MatchingParty) => this.renderMatchingParty(matchingParty))}
                </div>
            )
        }

        return (
            <div style={{
                display: 'flex',
                width: '80%',
                justifyContent: 'center',
                marginTop: '50px'
            }}>
                <Icon type={"loading"} spin={true} style={{ fontSize: '40px' }} />
            </div>
        );
    }
}