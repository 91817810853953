import * as React from "react";
import * as Types from "../../model/Types";
import {CenteredContent} from "../general/Styles";
import {Button} from "antd";
import "../../style/test/ValuesTestQuestion.scss";

interface ValuesTestQuestionProps {
    question: Types.PoliticalQuestion;
    questionNumber: number;
    onResponse: (weight: number) => void;
}

export class ValuesTestQuestion extends React.Component<ValuesTestQuestionProps> {

    constructor(props: ValuesTestQuestionProps) {
        super(props);
    }

    /**
     * Renders a coloured button indicting an opinion on a question.
     *
     * @param {string} text - the button text.
     * @param {string} colour - the hexadecimal colour code of the button.
     * @param {number} weight - the value to multiply the question's axis values by.
     *
     * @returns {JSX.Element}
     */
    public renderOpinionButton(text: string, colour: string, weight: number): JSX.Element {
        return (
            <span style={{ minWidth: '100%', display: 'flex', justifyContent: 'center' }}>
                <div className={"buttonWrapper"}>
                    <Button
                        type={"primary"}
                        size={"large"}
                        onClick={() => this.props.onResponse(weight)}
                        style={{
                            backgroundColor: colour,
                            border: 'none',
                            width: '100%',
                            fontSize: '20px',
                        }}
                    >
                        {text}
                    </Button>
                </div>
            </span>
        );
    }

    /**
     * Renders this component.
     *
     * @returns {any}
     */
    public render() {
        return (
            <CenteredContent style={{
                flexWrap: 'wrap',
                alignContent: 'flex-start',
                marginTop: '50px'
            }}>
                <span style={{
                    minWidth: '100%',
                    fontSize: '20px',
                    textAlign: 'center'
                }}>
                    STATEMENT {this.props.questionNumber} OF 50
                </span>

                <span className={"questionWrapper"}>
                    {this.props.question.question}
                </span>

                {this.renderOpinionButton("I strongly agree", "#135200", 1.0)}
                {this.renderOpinionButton("I agree", "#389e0d", 0.5)}
                {this.renderOpinionButton("I'm not sure", "#595959", 0)}
                {this.renderOpinionButton("I disagree", "#cf1322", -0.5)}
                {this.renderOpinionButton("I strongly disagree", "#820014", -1.0)}
            </CenteredContent>
        );
    }
}