import * as React from "react";
import * as Types from "../../model/Types";
import * as Api from "../../model/Api";
import {Select, Radio} from "antd";
import {Error} from "../general/Error";
import {LoadingIcon} from "../general/LoadingIcon";

interface ValuesTestDemographicsProps {
    identifier: string;
}

interface ValuesTestDemographicsState {
    parties: Types.Party[];
    errors: string[];

    age: number;
    gender: string;
    ethnicity: string;
    region: string;
    immigration: string;
    party: string;
    votingIntention: string;
    alreadyTaken: number;
}

export class ValuesTestDemographics extends React.Component<ValuesTestDemographicsProps, ValuesTestDemographicsState> {

    constructor(props: ValuesTestDemographicsProps) {
        super(props);

        this.state = this.getDefaultState();
    }

    /**
     * This function is called when the component is created.
     * It fetches a list of political parties from the backend.
     *
     * @returns void
     */
    public componentDidMount() {
        Api.get("party.php", response => {
            this.setState({
                parties: response as Types.Party[]
            });
        }, errors => this.setState({ errors }), [
            "includeMinor=true"
        ]);
    }

    /**
     * Returns this component's default state object.
     *
     * @returns {ValuesTestDemographicsState}
     */
    private getDefaultState(): ValuesTestDemographicsState {
        return {
            parties: [],
            errors: [],

            age: 19,
            gender: "Male",
            ethnicity: "New Zealand European",
            region: "Auckland",
            immigration: "Citizen",
            party: "NAT",
            votingIntention: "NAT",
            alreadyTaken: 0,
        };
    }

    /**
     * Returns an array of select options for each year and age back to 1919.
     *
     * @returns {any[]}
     */
    private generateYears(): any[] {
        const options: any[] = [];

        for (let i = 1; i < 100; i++) {
            options.push(<Select.Option key={i}>{2020 - i}</Select.Option>)
        }

        return options;
    }

    /**
     * Returns an array of select options for all ethnic groups we ask about.
     *
     * @returns {any[]}
     */
    private getEthnicityOptions(): any[] {
        return [
            "New Zealand European",
            "Maori",
            "Pacific Islander",
            "Asian",
            "Middle Eastern",
            "African",
            "Latin American",
            "Other",
        ].map((ethnicity: string) => (
            <Select.Option key={ethnicity}>{ethnicity}</Select.Option>
        ));
    }

    /**
     * Returns an array of select options for each region of New Zealand.
     *
     * @returns {any[]}
     */
    private getRegionOptions(): any[] {
        return [
            "Auckland",
            "Canterbury",
            "Wellington",
            "Waikato",
            "Bay of Plenty",
            "Manawatu-Whanganui",
            "Otago",
            "Northland",
            "Hawke's Bay",
            "Taranaki",
            "Southland",
            "Tasman",
            "Nelson",
            "Gisborne",
            "Marlborough",
            "West Coast",
            "I don't live in New Zealand",
        ].map((region: string) => (
            <Select.Option key={region}>{region}</Select.Option>
        ));
    }

    /**
     * Returns the list of parties in the state mapped to select options.
     *
     * @returns {any[]}
     */
    private getPartyOptions(): any[] {
        return this.state.parties.map((party: Types.Party) => {
            return <Select.Option key={party.code}>{party.name}</Select.Option>
        });
    }

    /**
     * Returns whether or not the state has been changed.
     *
     * @returns {boolean}
     */
    private hasStateChanged(): boolean {
        const defaultState: ValuesTestDemographicsState = this.getDefaultState();
        const currentState: ValuesTestDemographicsState = this.state;

        return !(
            defaultState.age === currentState.age &&
            defaultState.gender === currentState.gender &&
            defaultState.ethnicity === currentState.ethnicity &&
            defaultState.region === currentState.region &&
            defaultState.immigration === currentState.immigration &&
            defaultState.party === currentState.party &&
            defaultState.votingIntention === currentState.votingIntention &&
            defaultState.alreadyTaken === currentState.alreadyTaken
        );
    }

    /**
     * Returns whether or not this is running locally.
     *
     * @returns {boolean}
     */
    private isLocalhost(): boolean {
        return ["localhost", "127.0.0.1", ""].includes(window.location.hostname);
    }

    /**
     * Submit demographic data if the state has changed.
     *
     * @returns void
     */
    public onSubmit() {
        if (this.hasStateChanged() && !this.isLocalhost()) {
            const body = {
                identifier: this.props.identifier,
                age: this.state.age,
                gender: this.state.gender,
                ethnicity: this.state.ethnicity,
                region: this.state.region,
                immigration: this.state.immigration,
                party: this.state.party !== "DNV" ? this.state.party : null,
                votingIntention: this.state.votingIntention !== "DNV" ? this.state.votingIntention : null,
                alreadyTaken: this.state.alreadyTaken,
            };

            Api.post("submitdemographics.php", body, response => {
                // this block has been intentionally left blank.
            }, errors => this.setState({ errors }));
        }
    }

    /**
     * Renders the component.
     *
     * @returns {any}
     */
    public render() {
        const errors = this.state.errors;
        if (errors.length > 0) {
            return <Error key={errors.length} error={errors.join("\n")} />
        }

        if (this.state.parties.length > 0) {
            const questionStyle = { fontSize: '16px', fontWeight: 500 };
            const rowStyle = { display: 'block', width: '100%', margin: '5px 0 15px 0' };

            return (
                <div>
                    <span style={questionStyle}>In what year were you born?</span>
                    <span style={rowStyle}>
                        <Select
                            defaultValue={"19"}
                            onChange={(age: string) => this.setState({ age: Number(age) })}
                            style={{ width: '50%' }}
                        >
                            {this.generateYears()}
                        </Select>
                        <span style={{ margin: '10px' }}>about {this.state.age} years old</span>
                    </span>

                    <span style={questionStyle}>What gender do you identify with?</span>
                    <span style={rowStyle}>
                        <Radio.Group
                            defaultValue={"Male"}
                            buttonStyle={"solid"}
                            onChange={(event) => this.setState({ gender: event.target.value })}
                        >
                            <Radio.Button value={"Male"}>Male</Radio.Button>
                            <Radio.Button value={"Female"}>Female</Radio.Button>
                            <Radio.Button value={"Other"}>Other</Radio.Button>
                        </Radio.Group>
                    </span>

                    <span style={questionStyle}>What would you say is your primary ethnicity?</span>
                    <span style={rowStyle}>
                        <Select
                            defaultValue={"New Zealand European"}
                            style={{ width: '50%' }}
                            onChange={(ethnicity: string) => this.setState({ ethnicity })}
                        >
                            {this.getEthnicityOptions()}
                        </Select>
                    </span>

                    <span style={questionStyle}>What region do you live in?</span>
                        <span style={rowStyle}>
                        <Select
                            defaultValue={"Auckland"}
                            style={{ width: '50%' }}
                            onChange={(region: string) => this.setState({ region })}
                        >
                            {this.getRegionOptions()}
                        </Select>
                    </span>

                    <span style={questionStyle}>What is your current immigration status?</span>
                    <span style={rowStyle}>
                        <Select
                            defaultValue={"Citizen"}
                            style={{ width: '50%' }}
                            onChange={(immigration: string) => this.setState({ immigration })}
                        >
                            <Select.Option key={"Citizen"}>
                                I'm a New Zealand Citizen
                            </Select.Option>
                            <Select.Option key={"Permanent Resident"}>
                                I'm a Permanent Resident
                            </Select.Option>
                            <Select.Option key={"Visa"}>
                                I'm here on a Visa
                            </Select.Option>
                            <Select.Option key={"Overseas"}>
                                I don't live in New Zealand
                            </Select.Option>
                        </Select>
                    </span>

                    <span style={questionStyle}>Which party received your party vote in 2017?</span>
                    <span style={rowStyle}>
                        <Select
                            defaultValue={"NAT"}
                            style={{ width: '50%' }}
                            onChange={(party: string) => this.setState({ party })}
                        >
                            {this.getPartyOptions()}
                            <Select.Option key={"DNV"}>I did not/could not vote</Select.Option>
                        </Select>
                    </span>

                    <span style={questionStyle}>Which party do you intend to vote for in 2020?</span>
                    <span style={rowStyle}>
                        <Select
                            defaultValue={"NAT"}
                            style={{ width: '50%' }}
                            onChange={(party: string) => this.setState({ votingIntention: party })}
                        >
                            {this.getPartyOptions()}
                            <Select.Option key={"DNV"}>I can not/will not vote</Select.Option>
                        </Select>
                    </span>

                    <span style={questionStyle}>Have you given this information to Politikiwi before?</span>
                    <span style={rowStyle}>
                        <Radio.Group
                            defaultValue={0}
                            buttonStyle={"solid"}
                            onChange={(event) => this.setState({ alreadyTaken: event.target.value })}
                        >
                            <Radio.Button value={1}>Yes, I have</Radio.Button>
                            <Radio.Button value={0}>No, I haven't</Radio.Button>
                        </Radio.Group>
                    </span>
                </div>
            );
        }

        return <LoadingIcon />
    }
}