import {Ideology} from "../types/Ideology";

export const DEFINITIONS: Ideology[] = [
    {
        name: "Anarcho-Capitalism",
        personification: "Anarcho-Capitalist",
        desc: "Anarcho-capitalists believe in the abolition of the state in favour of a society based on private ownership and the free market.",
        axes: {
            social: 0.5,
            economic: 1.0,
            nation: 0.5,
            state: 1.0,
        }
    },
    {
        name: 'Libertarianism',
        personification: 'Libertarian',
        desc: "Libertarians believe in high levels of both personal and economic freedom, favouring a small government and a free market economy.",
        axes: {
            social: 0.65,
            economic: 0.7,
            nation: 0.6,
            state: 0.8,
        }
    },
    {
        name: "Libertarian Capitalism",
        personification: "Libertarian Capitalist",
        desc: "Libertarian capitalists believe that the government should only protect personal freedoms and should not intervene in the economy.",
        axes: {
            social: 0.65,
            economic: 0.9,
            nation: 0.6,
            state: 0.9,
        }
    },
    {
        name: "State Capitalism",
        personification: "State Capitalist",
        desc: "State capitalists believe that the government should own the means of production through for-profit state owned business.",
        axes: {
            social: 0.5,
            economic: 0.8,
            nation: 0.4,
            state: 0.1,
        }
    },
    {
        name: "Authoritarian Capitalism",
        personification: "Authoritarian Capitalist",
        desc: "Authoritarian capitalists believe the government should restrict personal freedoms but facilitate a free market economy.",
        axes: {
            social: 0.4,
            economic: 0.9,
            nation: 0.4,
            state: 0.1,
        }
    },
    {
        name: "Classical Liberalism",
        personification: "Classical Liberal",
        desc: "Classical liberals believe in a minimal state which protects individual freedoms and facilitates a free market economy.",
        axes: {
            social: 0.7,
            economic: 0.8,
            nation: 0.6,
            state: 0.8,
        }
    },
    {
        name: "Conservative Capitalism",
        personification: "Conservative Capitalist",
        desc: "Conservative capitalists hold traditional conservative views on social issues and favour a capitalist economy.",
        axes: {
            social: 0.2,
            economic: 0.75,
            nation: 0.4,
            state: 0.5,
        }
    },
    {
        name: "Neo-Liberalism",
        personification: "Neo-Liberal",
        desc: "Neo-liberals believe in economic liberalism, favouring austerity and the privatisation of government services.",
        axes: {
            social: 0.5,
            economic: 0.8,
            nation: 0.4,
            state: 0.5,
        }
    },
    {
        name: "Neo-Conservatism",
        personification: "Neo-Conservative",
        desc: "Neo-conservatives hold conservative views on social issues, favour national self-interest and a capitalist economy.",
        axes: {
            social: 0.4,
            economic: 0.75,
            nation: 0.25,
            state: 0.5,
        }
    },
    {
        name: "Traditional Conservatism",
        personification: "Traditional Conservative",
        desc: "Traditional conservatives hold culturally traditional views on social issues and believe in a strong hierarchical nation state.",
        axes: {
            social: 0.1,
            economic: 0.6,
            nation: 0.2,
            state: 0.4,
        }
    },
    {
        name: "Fascist Capitalism",
        personification: "Fascist Capitalist",
        desc: "Fascist capitalists believe in ethnic, religious and/or national supremacy over others and favour a free market economy.",
        axes: {
            social: 0.1,
            economic: 0.8,
            nation: 0.1,
            state: 0.25,
        }
    },
    {
        name: "Fascism",
        personification: "Fascist",
        desc: "Fascists believe in ethnic, religious and/or national supremacy over others and favour a strong authoritarian nation state.",
        axes: {
            social: 0.1,
            economic: 0.5,
            nation: 0.1,
            state: 0.1,
        }
    },
    {
        name: "Autocracy",
        personification: "Autocrat",
        desc: "Autocrats believe in a strong nation state governed by a single person or political party with absolute power.",
        axes: {
            social: 0.5,
            economic: 0.5,
            nation: 0.1,
            state: 0.1,
        }
    },
    {
        name: "Reactionary Conservatism",
        personification: "Reactionary",
        desc: "Reactionaries are intensely opposed to social progressivism and the modernisation of society.",
        axes: {
            social: 0.05,
            economic: 0.5,
            nation: 0.4,
            state: 0.4,
        }
    },
    {
        name: "Conservatism",
        personification: "Conservative",
        desc: "Conservatives are patriots who hold some traditional views on social issues and favour a capitalist economy.",
        axes: {
            social: 0.25,
            economic: 0.65,
            nation: 0.4,
            state: 0.5,
        }
    },
    {
        name: "Technocracy",
        personification: "Technocrat",
        desc: "Technocrats believe in an authoritarian government run by a progressive and well educated elite.",
        axes: {
            social: 0.8,
            economic: 0.4,
            nation: 0.6,
            state: 0.1,
        }
    },
    {
        name: "Modern Liberalism",
        personification: "Modern Liberal",
        desc: "Modern liberals believe in a progressive egalitarian society and a favour a mixed economy.",
        axes: {
            social: 0.7,
            economic: 0.45,
            nation: 0.6,
            state: 0.6,
        }
    },
    {
        name: "Centrism",
        personification: "Centrist",
        desc: "Centrists believe in a society with a balanced social and economic system, and oppose any societal or political shift.",
        axes: {
            social: 0.5,
            economic: 0.5,
            nation: 0.5,
            state: 0.5,
        }
    },
    {
        name: "Progressive Liberalism",
        personification: "Progressive Liberal",
        desc: "Progressive liberals believe in a highly progressive society and favour an equality-focused mixed economy.",
        axes: {
            social: 0.9,
            economic: 0.4,
            nation: 0.7,
            state: 0.4,
        }
    },
    {
        name: "Social Democracy",
        personification: "Social Democrat",
        desc: "Social democrats believe in an egalitarian society, favouring state intervention in the market to improve economic equality.",
        axes: {
            social: 0.9,
            economic: 0.3,
            nation: 0.7,
            state: 0.5,
        }
    },
    {
        name: "Libertarian Socialism",
        personification: "Libertarian Socialist",
        desc: "Libertarian socialists believe in the state ownership of the means of production and high levels of personal freedom.",
        axes: {
            social: 0.8,
            economic: 0.1,
            nation: 0.75,
            state: 0.8,
        }
    },
    {
        name: "Syndicalism",
        personification: "Syndicalist",
        desc: "Syndicalists believe in replacing the state with a system in which worker unions own the means of production.",
        axes: {
            social: 0.8,
            economic: 0.1,
            nation: 0.5,
            state: 1.0,
        }
    },
    {
        name: "Revolutionary Socialism",
        personification: "Revolutionary Socialist",
        desc: "Revolutionary socialists believe the state should seize private property and the means of the production to construct a classless society.",
        axes: {
            social: 0.6,
            economic: 0.1,
            nation: 0.2,
            state: 0.4,
        }
    },
    {
        name: "Democratic Socialism",
        personification: "Democratic Socialist",
        desc: "Democratic socialists believe a progressive democratic government should own and manage the means of production.",
        axes: {
            social: 0.8,
            economic: 0.1,
            nation: 0.5,
            state: 0.4,
        }
    },
    {
        name: "Conservative Socialism",
        personification: "Conservative Socialist",
        desc: "Conservative socialists hold conservative views on social issues and believe the state should own the means of production.",
        axes: {
            social: 0.2,
            economic: 0.1,
            nation: 0.3,
            state: 0.4,
        }
    },
    {
        name: "Stalinism",
        personification: "Stalinist",
        desc: "Stalinists believe in a centralised totalitarian state which aggressively pursues the implementation of Communism.",
        axes: {
            social: 0.5,
            economic: 0,
            nation: 0.2,
            state: 0,
        }
    },
    {
        name: "Leninism",
        personification: "Leninist",
        desc: "Leninists believe in the revolutionary installment of a dictatorship so socialism, and eventually communism, can be established.",
        axes: {
            social: 0.6,
            economic: 0.1,
            nation: 0.5,
            state: 0.2
        }
    },
    {
        name: "Global Communism",
        personification: "Global Communist",
        desc: "Global communists believe in the idea of a 'permanent revolution', with the goal of implementing communism across the world.",
        axes: {
            social: 0.75,
            economic: 0,
            nation: 1.0,
            state: 0.6,
        }
    },
    {
        name: "Anarcho-Communism",
        personification: "Anarcho-Communist",
        desc: "Anarcho-communists believe in a stateless communist society where workers collectively own all property and the means of production.",
        axes: {
            social: 0.8,
            economic: 0,
            nation: 0.6,
            state: 1.0,
        }
    }
];