import * as React from "react";
import {Icon, Input, Tooltip} from "antd";
import {createRef} from "react";
import "../../../style/test/results/ResultsPermalink.scss";

interface ResultsPermalinkProps {
    identifier: string;
}

interface ResultsPermalinkState {
    copyText: string;
}

export class ResultsPermalink extends React.Component<ResultsPermalinkProps, ResultsPermalinkState> {

    private permalinkInput = createRef<Input>();

    constructor(props: ResultsPermalinkProps) {
        super(props);

        this.state = {
            copyText: "Copy"
        };

        this.copyPermalink = this.copyPermalink.bind(this);
    }

    /**
     * Renders the content to the left of the permalink input field.
     *
     * @returns {JSX.Element}
     */
    public renderAddOnBefore(): JSX.Element {
        return (
            <Tooltip title={"You can bookmark this link to come back to your results at any time!"} placement={"bottom"}>
                <Icon type="link" />
            </Tooltip>
        );
    }

    /**
     * Renders the content to the right of the permalink input field.
     *
     * @returns {JSX.Element}
     */
    public renderAddOnAfter(): JSX.Element {
        return (
            <span
                onClick={this.copyPermalink}
                style={{ cursor: this.state.copyText === "Copy" ? 'pointer' : 'auto' }}
            >
                {this.state.copyText}
            </span>
        );
    }

    /**
     * Copies the content of the permalink input to the user's clipboard.
     *
     * @returns void
     */
    public copyPermalink() {
        const inputInstance: Input | null = this.permalinkInput.current;
        if (this.state.copyText !== "Copied!" && inputInstance !== null) {
            inputInstance.select();
            document.execCommand("copy");

            const selection = window.getSelection();
            if (selection !== null) {
                selection.removeAllRanges();
            }

            this.setState({ copyText: "Copied!" });
        }
    }

    /**
     * Renders the component.
     *
     * @returns {any}
     */
    public render() {
        return (
            <div className={"inputWrapper"}>
                <Input
                    size={"large"}
                    defaultValue={"https://politi.kiwi/values/" + this.props.identifier}
                    addonBefore={this.renderAddOnBefore()}
                    addonAfter={this.renderAddOnAfter()}
                    allowClear={false}
                    ref={this.permalinkInput}
                />
            </div>
        );
    }
}