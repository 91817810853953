import * as React from "react";
import * as Types from "../../model/Types";
import * as Api from "../../model/Api";
import {Error} from "../general/Error";
import {Button, Col, InputNumber, notification, Row, Tooltip} from "antd";
import {PartyResultRow} from "./PartyResultRow";
import {ParliamentSeatsGraphic} from "../general/ParliamentSeatsGraphic";
import {LoadingIcon} from "../general/LoadingIcon";
import "../../style/calculator/SeatCalculatorView.scss";

interface SeatCalculatorViewState {
    parties: Types.Party[];
    results: Types.PartyVotePollResult[];
    form: any;
    fetching: boolean;
    errors: string[];
}

export class SeatCalculatorView extends React.Component<{}, SeatCalculatorViewState> {

    constructor(props: any) {
        super(props);

        this.state = {
            parties: [],
            results: [],
            form: {},
            fetching: false,
            errors: [],
        };

        this.submit = this.submit.bind(this);
        this.onPartyVoteChange = this.onPartyVoteChange.bind(this);
        this.onElectorateChange = this.onElectorateChange.bind(this);
        this.onThresholdChange = this.onThresholdChange.bind(this);
    }

    /**
     * Fetches our aggregated poll from the backend to use as the initial form values.
     *
     * @returns void
     */
    public componentDidMount() {
        Api.get("aggregatepartyvotepoll.php", response => {
            const poll: Types.AggregatePoll = response[0] as Types.AggregatePoll;

            this.setState({
                parties: poll.results.map((result: Types.PartyVotePollResult) => result.party),
                results: poll.results,
                form: this.getDefaultForm(poll.results)
            });
        }, errors => this.setState({ errors }));
    }

    /**
     * Submits the form to the backend to calculate the seats won by each party.
     * The response is then encoded into the poll results in the state.
     *
     * @returns void
     */
    public submit() {
        const partyVoteSum: number = this.getPropertySum("result");
        if (partyVoteSum > 100) {
            notification.error({
                message: "Total Exceeds 100%",
                description: "The total of your party vote results is greater than 100 percent!",
                placement: "bottomRight",
            });

            return;
        }

        this.setState({
            fetching: true
        });

        Api.post("calculateseats.php", this.state.form, response => {
            let results: Types.PartyVotePollResult[] = [];
            Object.keys(response).forEach((code: string) => {
                // Find the party record with the code of the result's party.
                const party: Types.Party | undefined = this.state.parties
                     .find((p: Types.Party) => p.code === code);

                // Find any existing result for this party to prevent duplicates.
                const existingResult: Types.PartyVotePollResult | undefined = results
                     .find((result: Types.PartyVotePollResult) => result.party.code === code);

                if (party !== undefined && existingResult === undefined) {
                    results.push({
                        party,
                        result: response[code].result,
                        parliamentarySeats: response[code].seats,
                        electorates: response[code].electorates
                    });
                }
            });

            this.setState({
                results,
                fetching: false
            });
        }, errors => this.setState({ errors }));
    }

    /**
     * Loads the state copy of the inputted values with the default poll results.
     *
     * @param {PartyVotePollResult[]} results - the results to load from.
     *
     * @returns {any}
     */
    public getDefaultForm(results: Types.PartyVotePollResult[]): any {
        let form = {
            threshold: 5.00
        };

        results.forEach((result: Types.PartyVotePollResult) => {
            form[result.party.code] = {
                result: Number(result.result),
                electorates: Number(result.electorates),
            };
        });

        return form;
    }

    /**
     * Modifies the party vote value for a party result in the form.
     *
     * @param {string} partyCode - the three letter code representing the party.
     * @param {number} value - the numeric value to set this property to.
     *
     * @returns void
     */
    public onPartyVoteChange(partyCode: string, value: number) {
        this.modifyProperty(partyCode, "result", value);
    }

    /**
     * Modifies the electorate seat value for a party result in the form.
     *
     * @param {string} partyCode - the three letter code representing the party.
     * @param {number} value - the numeric value to set this property to.
     *
     * @returns void
     */
    public onElectorateChange(partyCode: string, value: number) {
        this.modifyProperty(partyCode, "electorates", value);
    }

    /**
     * Modifies a party result property in the form object in this component's state.
     *
     * @param {string} partyCode - the three letter code representing the party.
     * @param {string} key - the name of the property.
     * @param {number} value - the numeric value to set this property to.
     *
     * @returns void
     */
    public modifyProperty(partyCode: string, key: string, value: number) {
        this.setState({
            form: {
                ...this.state.form,
                [partyCode]: {
                    ...this.state.form[partyCode],
                    [key]: value
                }
            }
        });
    }

    /**
     * Modifies the threshold value in the form object of this component's state.
     *
     * @param {string | number | undefined} value - the value to set the threshold to.
     *
     * @returns void
     */
    public onThresholdChange(value: string | number | undefined) {
        this.setState({
            form: {
                ...this.state.form,
                threshold: Number(value)
            }
        })
    }

    /**
     * Get the sum of the given property on all party results in the form.
     *
     * @param {string} value - either "result" or "electorates"
     *
     * @returns {number}
     */
    private getPropertySum(value: string): number {
        return Object.keys(this.state.form)
            .filter((key: string) => key !== "threshold")
            .map((key: string) => this.state.form[key][value])
            .reduce((sum, current) => sum + current);
    }

    /**
     * Renders the form items which make up the party results form.
     *
     * @returns {JSX.Element}
     */
    public renderPartyResultsForm(): JSX.Element {
        return (
            <div>
                <Row>
                    <Col span={10} />
                    <Col span={6}>Party Vote (%)</Col>
                    <Col span={2} />
                    <Col span={6}>Electorates</Col>
                </Row>

                {this.state.results
                    .sort((r1: Types.PartyVotePollResult, r2: Types.PartyVotePollResult) => r2.result - r1.result)
                    .map((result: Types.PartyVotePollResult) => {
                        return (
                            <span key={result.party.code}>
                                <PartyResultRow
                                    result={result}
                                    onPartyVoteChange={this.onPartyVoteChange}
                                    onElectorateChange={this.onElectorateChange}
                                />
                                <br/>
                            </span>
                        );
                    })}

                <span>
                    <Row style={{ fontSize: '20px' }}>
                        <Col span={10}>
                            <b>Total</b>
                        </Col>
                        <Col span={6}>
                            <InputNumber value={this.getPropertySum("result")} />
                        </Col>
                        <Col span={2} />
                        <Col span={6}>
                            <InputNumber value={this.getPropertySum("electorates")} />
                        </Col>
                    </Row>
                </span>
                <br />
                <span>
                    <Row style={{ fontSize: '18px' }}>
                        <Col span={10}>
                            <Tooltip title={"The party vote percentage which a party must reach to enter Parliament."}>
                                Threshold (%)
                            </Tooltip>
                        </Col>
                        <Col span={6}>
                            <InputNumber
                                defaultValue={this.state.form.threshold}
                                onChange={this.onThresholdChange}
                            />
                        </Col>
                        <Col span={2} />
                        <Col span={6}>
                            <Button
                                type={"primary"}
                                loading={this.state.fetching}
                                onClick={this.submit}
                            >
                                Calculate
                            </Button>
                        </Col>
                    </Row>
                </span>
            </div>
        );
    }

    /**
     * Renders this component.
     *
     * @returns {any}
     */
    public render() {
        const errors: string[] = this.state.errors;
        if (errors.length > 0) {
            return <Error key={errors.length} error={errors.join("\n")} />
        }

        if (this.state.results.length > 0) {
            return (
                <div style={{ margin: '15px' }}>
                    <div className={"inputs"}>
                        {this.renderPartyResultsForm()}
                    </div>

                    <div
                        className={"responsiveGraphic"}
                        style={{
                            opacity: this.state.fetching ? 0.3 : 1.0
                        }}
                    >
                        <div className={"graphicWrapper"}>
                            <ParliamentSeatsGraphic
                                results={this.state.results}
                                sizeOfParliament={120}
                                showSeatCounts={true}
                            />
                        </div>
                    </div>
                </div>
            )
        }

        return <LoadingIcon />
    }
}