/**
 * The web address of the Politikiwi backend.
 *
 * @type {string}
 */
export const BACKEND: string = "https://backend.politi.kiwi/";

/**
 * The order parties should be displayed from left to right on a parliamentary graphic.
 * This is based on the make-up of the current government.
 *
 * @var {string[]}
 */
export const PARTY_ORDER: string[] = ["LAB", "GRN", "MRI", "TOP", "NZF", "NCP", "ACT", "NAT"];

/**
 * The order parties should be displayed from left to right on a parliamentary graphic.
 * This is based on the make-up of a potential National government.
 *
 * @type {string[]}
 */
export const PARTY_ORDER_REVERSED: string[] = ["NAT", "ACT", "NCP", "NZF", "TOP", "MRI", "GRN", "LAB"];

/**
 * An array of party codes which make up the current government.
 *
 * @type {string[]}
 */
export const GOVERNMENT: string[] = ["LAB"];

/**
 * An array of party codes which represent the major parties.
 *
 * @type {string[]}
 */
export const MAJOR_PARTIES: string[] = ["NAT", "LAB"];

/**
 * An array of party codes which represent the minor parties.
 *
 * @type {string[]}
 */
export const MINOR_PARTIES: string[] = ["GRN", "NZF", "ACT", "TOP", "MRI", "NCP"];

/**
 * An array of party codes which represent parties that would likely be a part of a National government.
 *
 * @type {string[]}
 */
export const NATIONAL_COALITION: string[] = ["NAT", "ACT", "NCP"];

/**
 * An array of party codes which represent parties that would likely be a part of a Labour government.
 *
 * @type {string[]}
 */
export const LABOUR_COALITION: string[] = ["LAB", "GRN", "NZF", "MRI", "TOP"];

/**
 * The total party vote percentage achieved by parties in the current government at the last election.
 *
 * @type {number}
 */
export const GOVERNMENT_TOTAL_PARTY_VOTE: number = 50.01;

/**
 * The total number of Parliamentary seats in the current government at the last election.
 *
 * @type {number}
 */
export const GOVERNMENT_TOTAL_SEATS: number = 65;