import * as React from 'react';
import * as Types from '../../model/Types';
import * as Api from '../../model/Api';
import {Error} from '../general/Error';
import {PartyVoteResult} from "./PartyVoteResult";
import {Checkbox, Radio} from "antd";
import styled from "@emotion/styled";
import {ParliamentSeatsGraphic} from "../general/ParliamentSeatsGraphic";
import {LoadingIcon} from "../general/LoadingIcon";

interface AggregatePollingState {
    poll: Types.AggregatePoll | null;
    showSeats: boolean;
    showMinorParties: boolean;
    errors: string[];
}

export class AggregatePolling extends React.Component<{}, AggregatePollingState> {

    constructor(props: any) {
        super(props);

        this.state = {
            poll: null,
            showSeats: false,
            showMinorParties: false,
            errors: []
        };

        this.onPreferenceToggle = this.onPreferenceToggle.bind(this);
        this.onPartyVisibilityToggle = this.onPartyVisibilityToggle.bind(this);
    }

    /**
     * Loads the aggregated party vote poll from the backend into the state.
     *
     * @returns void
     */
    public componentDidMount() {
        Api.get("aggregatepartyvotepoll.php", response => {
            this.setState({
                poll: response[0] as Types.AggregatePoll
            });
        }, errors => this.setState({ errors }));
    }

    /**
     * Returns the poll results to render based on the selected preference.
     *
     * @returns {PartyVotePollResult[]}
     */
    public reduceResults(): Types.PartyVotePollResult[] {
        if (this.state.poll === null) {
            return [];
        }

        let results: Types.PartyVotePollResult[] = this.state.poll.results;
        if (!this.state.showMinorParties) {
            results = results.filter((result: Types.PartyVotePollResult) => result.party.currentSeats > 0);
        }

        return results.sort((a: Types.PartyVotePollResult, b: Types.PartyVotePollResult) => this.compare(a, b));
    }

    /**
     * Compares two party vote poll results for ordering.
     *
     * @param {PartyVotePollResult} a - the first result to compare.
     * @param {PartyVotePollResult} b - the second result to compare.
     *
     * @returns {number}
     */
    private compare(a: Types.PartyVotePollResult, b: Types.PartyVotePollResult): number {
        return this.state.showSeats ? (b.parliamentarySeats - a.parliamentarySeats) : (b.result - a.result);
    }

    /**
     * Toggles the preference between party vote and parliamentary seats.
     *
     * @returns void
     */
    public onPreferenceToggle() {
        this.setState({
            showSeats: !this.state.showSeats,
        });
    }

    /**
     * Toggles the visibility of minor parties.
     *
     * @returns void
     */
    public onPartyVisibilityToggle() {
        this.setState({
            showMinorParties: !this.state.showMinorParties,
        });
    }

    /**
     * Renders the preference toggle radio buttons.
     *
     * @returns {JSX.Element}
     */
    public renderPreferenceToggle(): JSX.Element {
        return (
            <span style={{
                display: 'inline-flex',
                width: '100%',
                marginTop: '10px'
            }}>
                <Checkbox
                    checked={this.state.showMinorParties}
                    onChange={this.onPartyVisibilityToggle}
                    style={{ width: '50%', marginLeft: '10px' }}
                >
                    Show Minor Parties
                </Checkbox>

                <div style={{ display: 'flex', width: '50%' }}>
                    <Radio.Group
                        value={this.state.showSeats ? "seats" : "vote"}
                        onChange={this.onPreferenceToggle}
                        style={{ marginLeft: 'auto' }}
                    >
                        <Radio value={"vote"}>Party Vote Percentage</Radio>
                        <Radio value={"seats"}>Parliamentary Seats</Radio>
                    </Radio.Group>
                </div>
            </span>
        );
    }

    /**
     * Renders each individual party result.
     *
     * @param {PartyVotePollResult[]} results - the results to render.
     *
     * @returns {JSX.Element}
     */
    public renderResults(results: Types.PartyVotePollResult[]): JSX.Element {
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                margin: '10px'
            }}>
                {results.map((result: Types.PartyVotePollResult, index: number) => {
                    return (
                        <div key={result.party.code} style={{ width: '100%' }}>
                            <PartyVoteResult
                                result={result}
                                renderLine={index < (results.length - 1)}
                                seats={this.state.showSeats}
                            />
                        </div>
                    );
                })}
            </div>
        );
    }

    /**
     * Renders the component.
     *
     * @returns {any}
     */
    public render() {
        const errors: string[] = this.state.errors;
        if (errors.length > 0) {
            return <Error key={errors.length} error={errors.join("\n")} />
        }

        const ResponsiveResults = styled.div`
            width: 100%;
            
            @media only screen and (min-width: 1200px) {
                float: left;
                margin-top: 25px;
                width: 60%;
            }
        `;

        const ResponsiveGraphic = styled.div`
            display: flex;
            justify-content: center;
            width: 100%;
            
            @media only screen and (min-width: 1200px) {
                float: right;
                width: 40%;
            }
        `;

        const GraphicWrapper = styled.div`
            width: 100%;
            
            @media only screen and (min-width: 800px) {
                width: 80%;
            }
            
            @media only screen and (min-width: 1200px) {
                width: 60%;
            }
        `;

        if (this.state.poll != null) {
            const results: Types.PartyVotePollResult[] = this.reduceResults();

            return (
                <div>
                    <ResponsiveResults>
                        {this.renderPreferenceToggle()}
                        {this.renderResults(results)}
                    </ResponsiveResults>

                    <ResponsiveGraphic>
                        <GraphicWrapper>
                            <ParliamentSeatsGraphic
                                results={this.state.poll.results}
                                sizeOfParliament={120}
                                showSeatCounts={true}
                            />
                        </GraphicWrapper>
                    </ResponsiveGraphic>
                </div>
            );
        }
    
        return <LoadingIcon />
    }
}