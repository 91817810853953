import * as React from 'react';
import { Alert } from 'antd';

interface ErrorProps {
    error: string;
}

export class Error extends React.Component<ErrorProps> {
    
    constructor(props: ErrorProps) {
        super(props);
    }

    public render() {
        return (
            <Alert 
                message="An error has occurred when loading this page component!" 
                description={this.props.error}
                type="error"
                showIcon={true}
            />
        );
    }
}