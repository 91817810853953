import * as React from 'react';
import * as Types from "../../model/Types";
import * as Utilities from "../../model/Utilities";
import * as Constants from '../../model/Constants';
import {CartesianGrid, DotProps, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';

interface PollingTrendGraphProps {
    polls: Types.PartyVotePoll[];
    parties: Types.Party[];
    seats: boolean;
    major: boolean;
}

interface PollingTrendGraphState {
    visibleParties: string[];
}

export class PollingTrendGraph extends React.Component<PollingTrendGraphProps, PollingTrendGraphState> {

    constructor(props: PollingTrendGraphProps) {
        super(props);

        this.state = {
            visibleParties: props.parties
                .filter((party: Types.Party) => this.shouldIncludeParty(party.code))
                .map((party: Types.Party) => party.code),
        };

        this.renderLegend = this.renderLegend.bind(this);
        this.togglePartyVisibility = this.togglePartyVisibility.bind(this);
    }

    /**
     * Returns whether or not results for the party
     * with the given code should be shown on this graph.
     *
     * @param {string} code - the party's three letter code.
     *
     * @returns {boolean}
     */
    public shouldIncludeParty(code: string): boolean {
        const parties: string[] = this.props.major ? Constants.MAJOR_PARTIES : Constants.MINOR_PARTIES;
        return parties.indexOf(code) > -1;
    }

    /**
     * Returns whether or not the party with the given code is visible on the graph.
     *
     * @param {string} code - the party's three letter code.
     *
     * @returns {boolean}
     */
    public isPartyVisible(code: string): boolean {
        return this.state.visibleParties.indexOf(code) > -1;
    }

    /**
     * Generates the data set for this graph.
     *
     * @returns {any[]}
     */
    public getDataSet() {
        return this.props.polls
            .map((poll: Types.PartyVotePoll) => {
                let line = {
                    date: Utilities.getUnixTimestamp(poll.startDate)
                };

                poll.results
                    .filter((result: Types.PartyVotePollResult) => this.shouldIncludeParty(result.party.code))
                    .forEach((result: Types.PartyVotePollResult) => {
                        line[result.party.code] = (this.props.seats ? result.parliamentarySeats : result.result);
                    });

                return line;
            });
    }

    /**
     * Renders a solid colour dot.
     *
     * This takes a active parameter to determine which old prop to use for the fill colour.
     * An active dot had the party colour as it's fill, whereas a normal dot had the party colour as the stroke.
     *
     * @param props - the props of the default dot.
     * @param {boolean} active - whether or not to render an active dot.
     *
     * @returns {JSX.Element}
     */
    public renderDot(props: any, active: boolean): JSX.Element {
        return (
            <circle
                cx={props.cx}
                cy={props.cy}
                r={props.r}
                fill={active ? props.fill : props.stroke}
                opacity={props.opacity}
            />
        );
    }

    /**
     * Renders a custom legend for the graph.
     *
     * @returns {JSX.Element}
     */
    public renderLegend(): JSX.Element {
        return (
            <div style={{
                display: 'inline-flex',
                width: '100%',
                margin: '15px 0 15px 0',
                fontSize: '16px',
                justifyContent: 'center'
            }}>
                <b>Show / Hide Parties:</b>

                {this.props.parties
                    .filter((party: Types.Party) => this.shouldIncludeParty(party.code))
                    .map((party: Types.Party) => {
                        const color: string = this.isPartyVisible(party.code) ? party.colour : "gray";

                        return (
                            <b
                                key={party.code}
                                onClick={() => this.togglePartyVisibility(party.code)}
                                style={{color, margin: '0 10px 0 10px', cursor: 'pointer'}}
                            >
                                {party.code}
                            </b>
                        );
                    })}
            </div>
        );
    }

    /**
     * Toggles the visibility of the party with the given code's results on the graph.
     *
     * @param {string} code - the three letter code of the party.
     *
     * @returns void
     */
    public togglePartyVisibility(code: string) {
        let updatedVisibilityArray: string[];

        if (this.isPartyVisible(code)) {
            // remove the party code from the array in the state by filtering it out.
            updatedVisibilityArray = this.state.visibleParties.filter((partyCode: string) => partyCode !== code);
        } else {
            // add the party code to the array in the state.
            updatedVisibilityArray = [...this.state.visibleParties, code];
        }

        this.setState({ visibleParties: updatedVisibilityArray });
    }

    /**
     * Renders the text component shown on the graph when all parties have been hidden.
     *
     * @returns {JSX.Element}
     */
    public renderEmptyText(): JSX.Element {
        return (
            <text
                x={"53%"}
                y={"40%"}
                textAnchor={"middle"}
                style={{ fontSize: "16px" }}
                opacity={0.65}
            >
                You have hidden all party results.
            </text>
        );
    }

    /**
     * Renders the component.
     *
     * @returns {any}
     */
    public render() {
        return (
            <ResponsiveContainer width={"90%"} height={300}>
                <LineChart data={this.getDataSet()}>
                    <CartesianGrid
                        verticalPoints={[0]}
                        vertical={false}
                    />

                    <XAxis
                        dataKey={"date"}
                        hide={true}
                        scale={"time"}
                        allowDataOverflow={false}
                        // domain={[1602846060000, 1698750060000]}
                    />

                    <YAxis/>

                    <Tooltip labelFormatter={(label) => Utilities.formatDate(label)} />
                    <Legend content={this.renderLegend} />

                    {this.state.visibleParties.length > 0 ? this.props.parties
                        .filter((party: Types.Party) => this.shouldIncludeParty(party.code))
                        .filter((party: Types.Party) => this.isPartyVisible(party.code))
                        .map((party: Types.Party) => {
                            return <Line
                                type={"linear"}
                                dataKey={party.code}
                                stroke={party.colour}
                                strokeWidth={2}
                                dot={(props: DotProps) => this.renderDot(props, false)}
                                activeDot={(props: DotProps) => this.renderDot(props, true)}
                                key={party.code}
                                animationDuration={1000}
                            />
                        }) : this.renderEmptyText()}
                </LineChart>
            </ResponsiveContainer>
        );
    }
}