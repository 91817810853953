import styled from "@emotion/styled";

export const HeaderWrapper = styled.div({
    display: 'block',
    width: '100%',
    margin: '5px 5px 20px 5px'
});

export const LeftHalf = styled.div`
    float: left;
    width: 100%;
    
    @media only screen and (min-width: 1300px) {
        width: 50%;
    }
`;

export const RightHalf = styled.div`
    float: right;
    width: 100%;
    
    @media only screen and (min-width: 1300px) {
        width: 50%;
    }
`;

export const Left40 = styled.div`
    float: left;
    width: 100%;
    
    @media only screen and (min-width: 1550px) {
        width: 40%;
    }
`;

export const Left60 = styled.div`
    float: left;
    width: 100%;
    
    @media only screen and (min-width: 1550px) {
        width: 60%;
    }
`;

export const Right40 = styled.div`
    float: right;
    width: 100%;
    
    @media only screen and (min-width: 1550px) {
        width: 40%;
    }
`;

export const Right60 = styled.div`
    float: right;
    width: 100%;
    
    @media only screen and (min-width: 1550px) {
        width: 60%;
    }
`;

export const PageHeaderText = styled.span`
    font-size: 30px;
    
    @media only screen and (min-width: 1200px) {
        font-size: 40px;
    }  
`;

export const HeaderText = styled.span({
    fontSize: '25px'
});

export const Text = styled.span({
    fontSize: '16px'
});

export const CenteredContent = styled.div({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});
