import * as React from "react";
import * as Types from "../../model/Types";
import {Tooltip} from "antd";
import * as Utilities from "../../model/Utilities";

interface ParliamentSeatsGraphicProps {
    results: Types.PartyVotePollResult[];
    sizeOfParliament: number;
    showSeatCounts: boolean;
}

export class ParliamentSeatsGraphic extends React.Component<ParliamentSeatsGraphicProps> {

    constructor(props: ParliamentSeatsGraphicProps) {
        super(props);
    }

    /**
     * Returns an array of sorted party vote poll results.
     *
     * @returns {PartyVotePollResult[]}
     */
    public sortResults(): Types.PartyVotePollResult[] {
        let ordered: Types.PartyVotePollResult[] = [];

        for (let code of Utilities.getPartyOrder(this.props.results)) {
            let result = this.props.results.find(r => r.party.code == code);
            if (result !== undefined && result.parliamentarySeats > 0) {
                ordered.push(result);
            }
        }

        return ordered;
    }

    /**
     * Returns an array of seat IDs associated with the allocated party.
     *
     * @returns {any[]}
     */
    public getPartySeatAllocations(): any[] {
        let seats = [];
        let count = 0;

        for (let result of this.sortResults()) {
            for (let s = 0; s < result.parliamentarySeats; s++) {
                seats.push({
                    count,
                    party: result.party,
                });

                count++;
            }
        }

        seats.splice(this.props.sizeOfParliament);
        return seats;
    }

    /**
     * Returns the coordinate offset for each seat in the parliament graphic.
     *
     * @returns {string[]}
     */
    public getTranslations(): string[] {
        return [
            "79,25", "60,25", "41,25", "79,44", "60,44", "41,44",
            "79,63", "60,63", "41,63", "79,82", "60,82", "41,82",
            "79,101", "60,101", "41,101", "79,120", "60,120", "41,120",
            "79,139", "60,139", "41,139", "79,158", "60,158", "41,158",
            "79,177", "60,177", "41,177", "79,198", "60,197", "41,196",
            "83,218", "62,216", "42,215", "68,233", "47,232", "55,248",
            "95,234", "80,248", "68,261", "48,268", "33,280", "61,280",
            "48,295", "81,272", "76,292", "68,309", "96,259", "96,281",
            "93,301", "87,319", "112,246", "113,266", "113,286", "111,306",
            "109,325", "132,249", "132,268", "132,287", "132,307", "132,327",
            "166,249", "166,268", "166,287", "166,307", "166,327", "186,246",
            "185,266", "185,286", "187,306", "189,325", "202,259", "202,281",
            "205,301", "211,319", "230,309", "222,292", "217,272", "250,295",
            "237,280", "265,280", "250,268", "230,261", "218,248", "203,234",
            "243,248", "251,232", "230,233", "256,215", "236,216", "215,218",
            "257,196", "238,197", "219,198", "257,177", "238,177", "219,177",
            "257,158", "238,158", "219,158", "257,139", "238,139", "219,139",
            "257,120", "238,120", "219,120", "257,101", "238,101", "219,101",
            "257,82", "238,82", "219,82", "257,63", "238,63", "219,63",
            "257,44", "238,44", "219,44", "257,25", "238,25", "219,25",
        ];
    }

    /**
     * Renders the seat counts for each party in the center of the diagram.
     *
     * @returns {JSX.Element}
     */
    public renderSeatCounts(): JSX.Element {
        return (
            <text
                x="50%"
                y="10%"
                dominantBaseline="middle"
                textAnchor="middle"
            >
                {this.props.results
                    .sort((r1: Types.PartyVotePollResult, r2: Types.PartyVotePollResult) => r2.parliamentarySeats - r1.parliamentarySeats)
                    .map((result: Types.PartyVotePollResult) => {
                        return (
                            <tspan
                                x={"50%"}
                                dy={"1.2em"}
                                fill={result.party.colour}
                                fontWeight={"bold"}
                                key={result.party.code}
                            >
                                {result.party.code} {result.parliamentarySeats}
                            </tspan>
                        );
                    })}
            </text>
        );
    }

    /**
     * Renders this component.
     *
     * @returns {any}
     */
    public render() {
        const seats = this.getPartySeatAllocations();

        return (
            <svg viewBox={"0 0 300 344"} style={{ width: '100%' }}>
                {this.props.showSeatCounts && this.renderSeatCounts()}

                {seats.map(seat => {
                    return (
                        <Tooltip title={seat.party.name + " Seat"} key={seat.count}>
                            <circle
                                r={"7"}
                                transform={"translate(" + this.getTranslations()[seat.count] + ")"}
                                fill={seat.party.colour}
                            />
                        </Tooltip>
                    );
                })}
            </svg>
        );
    }
}