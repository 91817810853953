import * as React from "react";
import {HeaderWrapper, PageHeaderText, Text} from "../components/general/Styles";
import {SeatCalculatorView} from "../components/calculator/SeatCalculatorView";
import { Alert } from "antd";

export class SeatCalculator extends React.Component {

    public render() {
        return (
            <div>
                <Alert
                    message={"Politikiwi has been retired"}
                    description={"This project is no longer actively maintained. This calculator utility should still work, but the default party results are based on an aggregate poll which uses outdated polling results, and so don't reflect the current political landscape."}
                    type={"warning"}
                    showIcon={true}
                />
                <br/>
                <HeaderWrapper>
                    <PageHeaderText>Parliamentary Seat Calculator</PageHeaderText>
                    <br/>
                    <Text>
                        Our seat calculator utility enables you to input the results of an election and see how they
                        translate to seats in Parliament. If your values result in overhang seats they will not be displayed on the diagram.
                    </Text>
                </HeaderWrapper>

                <SeatCalculatorView />
            </div>
        );
    }
}