import * as React from 'react';
import { Alert } from "antd";

export class About extends React.Component {

    /**
     * Renders text which when clicked directs to a given URL.
     *
     * @param {string} href - the URL to direct to on click.
     * @param {string} text - the text to be displayed as a link.
     *
     * @returns {any}
     */
    private renderLink(href: string, text: string) {
        return (
            <a href={href} style={{ color: '#1890ff' }}>{text}</a>
        );
    }

    /**
     * Renders the component.
     *
     * @returns {any}
     */
    public render() {
        return (
            <div style={{ fontSize: '16px' }}>
                <Alert
                    message={"Politikiwi has been retired"}
                    description={"This project is no longer actively maintained."}
                    type={"warning"}
                    showIcon={true}
                />
                <br/>

                <b style={{ fontSize: '18px' }}>What is Politikiwi and who is behind it?</b><br />
                Politikiwi provides unique data analysis insights into New Zealand politics.<br />
                <br />
                It was developed and managed by Robert Calvert, a web developer and software engineer.<br />
                <br />
                Politikiwi is owned and maintained in a strictly personal capacity, and is not related to or associated with any current or former employers.<br />
                <br />
                Any enquiries can be emailed to {this.renderLink("mailto:contact@politi.kiwi", "contact@politi.kiwi")}.<br/>
                <br />
                <b style={{ fontSize: '18px' }}>Privacy and Data</b><br />
                <br />
                Politikiwi encourages visitors to read our {this.renderLink("privacy", "privacy policy")} to understand what data we collect.<br />
            </div>
        );
    }
}