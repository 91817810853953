import * as React from "react";
import * as Constants from "../../../model/test/TestConstants";
import styled from "@emotion/styled";
import {Icon, Tooltip} from 'antd';
import "../../../style/test/results/PoliticalAxisResult.scss";

interface PoliticalAxisResultProps {
    axis: string;
    value: number;
}

export class PoliticalAxisResult extends React.Component<PoliticalAxisResultProps> {

    constructor(props: PoliticalAxisResultProps) {
        super(props);
    }

    /**
     * Returns the title of this position on the axis.
     *
     * @returns {string}
     */
    public getTitle(): string {
        const value: number = this.props.value;
        const titles: string[] = Constants.TITLES[this.props.axis.toLowerCase()];

        if (value >= 0.8) {
            return titles[0];
        } else if (value >= 0.6) {
            return titles[1];
        } else if (value >= 0.4) {
            return titles[2];
        } else if (value >= 0.2) {
            return titles[3];
        }

        return titles[4];
    }

    /**
     * Renders the bar graphic for this axis.
     *
     * @returns {JSX.Element}
     */
    public renderBar(): JSX.Element {
        const axisProperty: string = this.props.axis.toLowerCase();
        const titles: string[] = Constants.TITLES[axisProperty];
        const colours = Constants.COLOURS[axisProperty];
        const icons = Constants.ICONS[axisProperty];

        const BarSegment = styled.div({
            display: 'block',
            height: '40px',
            border: '3px solid #5c6b77'
        });

        const PercentageText = styled.span({
            fontSize: '20px',
            color: 'white',
            margin: '10px',
        });

        return (
			<div style={{ display: 'inline-flex', width: '100%' }}>
                <Tooltip title={titles[0]} placement={"left"}>
                    <Icon type={icons.left} style={{ fontSize: '40px', margin: '5px' }} />
                </Tooltip>

				<BarSegment style={{
                    width: (this.props.value * 100) + '%',
                    backgroundColor: colours.primary,
                    margin: '5px 0px 5px 5px',
                    borderRadius: '10px 0px 0px 10px',
                    borderRight: 0
                }}>
                    <PercentageText style={{
                        display: this.props.value >= 0.1 ? 'block' : 'none',
                        float: 'right'
                    }}>
                        {(this.props.value * 100).toFixed(0)}%
                    </PercentageText>
                </BarSegment>

				<BarSegment style={{
                    width: ((1 - this.props.value) * 100) + '%',
                    backgroundColor: colours.secondary,
                    margin: '5px 5px 5px 0px',
                    borderRadius: '0px 10px 10px 0px',
                    borderLeft: 0
                }}>
                    <PercentageText style={{
                        display: (1 - this.props.value) >= 0.1 ? 'block' : 'none',
                        float: 'left'
                    }}>
                        {((1- this.props.value) * 100).toFixed(0)}%
                    </PercentageText>
                </BarSegment>

                <Tooltip title={titles[4]} placement={"right"}>
                    <Icon type={icons.right} style={{ fontSize: '40px', margin: '5px' }} />
                </Tooltip>
			</div>
        );
    }

    /**
     * Renders the component.
     *
     * @returns {any}
     */
    public render() {
        return (
            <div className={"resultWrapper"}>
                <span style={{
                    display: 'inline-block',
                    width: '100%',
                    fontSize: '20px',
                    margin: '15px 0px 15px 0px',
                }}>
                    <b>{this.props.axis} Axis:</b> {this.getTitle()}
                </span>

                {this.renderBar()}
            </div>
        );
    }
}