import * as React from "react";
import {CenteredContent} from "../general/Styles";
import { Button, Icon, Modal } from "antd";
import {ValuesTestInfo} from "./ValuesTestInfo";

interface ValuesTestStartProps {
    onStart: () => void;
}

interface ValuesTestStartState {
    viewingMore: boolean;
    fetching: boolean;
}

export class ValuesTestStart extends React.Component<ValuesTestStartProps, ValuesTestStartState> {

    constructor(props: ValuesTestStartProps) {
        super(props);

        this.state = {
            viewingMore: false,
            fetching: false,
        };

        this.toggleModalVisibility = this.toggleModalVisibility.bind(this);
        this.beforeStart = this.beforeStart.bind(this);
    }

    /**
     * This function runs when the start test button is clicked but before the test starts.
     * It currently updates the fetching state so the button appears to load whilst fetching questions.
     *
     * @return void
     */
    public beforeStart() {
        this.setState({
            fetching: true
        });

        this.props.onStart();
    }

    /**
     * Toggles the visibility of the information modal.
     *
     * @returns void
     */
    public toggleModalVisibility() {
        this.setState({
            viewingMore: !this.state.viewingMore
        });
    }

    /**
     * Renders a large primary button.
     *
     * @param {string} text - the button's text.
     * @param {() => void} onClick - the code to run when this button is clicked.
     *
     * @returns {JSX.Element}
     */
    public renderButton(text: string, onClick: () => void): JSX.Element {
        return (
            <Button
                type={"primary"}
                size={"large"}
                onClick={onClick}
                style={{
                    height: '50px',
                    fontSize: '30px',
                    margin: '50px'
                }}
            >
                {text}
            </Button>
        );
    }

    /**
     * Renders the specified icons in an evenly spaced line.
     *
     * @param {string[]} icons - the icon types to display.
     *
     * @returns {JSX.Element}
     */
    public renderIcons(icons: string[]): JSX.Element {
        return (
            <div style={{ display: 'inline-flex', fontSize: '35px' }}>
                {icons.map((icon: string) => {
                    return <Icon
                        type={icon}
                        style={{ padding: '0 10px 0 10px' }}
                        key={icon}
                    />
                })}
            </div>
        );
    }

    /**
     * Renders the component.
     *
     * @returns {any}
     */
    public render() {
        return (
            <CenteredContent style={{
                flexWrap: 'wrap',
                alignContent: 'flex-start',
                marginTop: '200px'
            }}>
                {this.renderIcons(["team", "dollar", "global", "bank", "flag", "cloud"])}

                <span style={{ minWidth: '100%', fontSize: '50px', textAlign: 'center' }}>
                    Measure Your Political Values
                </span>

                <span style={{ minWidth: '100%', fontSize: '18px', textAlign: 'center' }}>
                    Take our custom test to measure your political values across four axes.
                </span>

                {this.renderButton("Take the Test", this.toggleModalVisibility)}

                <Modal
                    title={"About the Test"}
                    visible={this.state.viewingMore}
                    centered={true}
                    okText={"Take the Test"}
                    onOk={this.beforeStart}
                    confirmLoading={this.state.fetching}
                    onCancel={this.toggleModalVisibility}
                >
                    <ValuesTestInfo />
                </Modal>
            </CenteredContent>
        );
    }
}