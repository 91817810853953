import * as React from 'react';
import * as Types from '../../model/Types';
import * as Utilities from '../../model/Utilities';
import * as Api from '../../model/Api';
import {Error} from "../general/Error";
import {Table, Radio, Alert} from "antd";
import {PartyVotePollDetails} from "./PartyVotePollDetails";
import {PartyVotePollingGraphs} from "./PartyVotePollingGraphs";
import "../../style/polling/PartyVotePollsTable.scss";
import {LoadingIcon} from "../general/LoadingIcon";

interface PartyVotePollsTableProps {
    pollsToShow: number;
}

interface PartyVotePollsTableState {
    parties: Types.Party[];
    polls: Types.PartyVotePoll[];
    errors: string[];
    showSeats: boolean;
}

export class PartyVotePollsTable extends React.Component<PartyVotePollsTableProps, PartyVotePollsTableState> {

    constructor(props: PartyVotePollsTableProps) {
        super(props);

        this.state = {
            parties: [],
            polls: [],
            errors: [],
            showSeats: false,
        };

        this.onResultPreferenceToggle = this.onResultPreferenceToggle.bind(this);
        this.renderResultColumn = this.renderResultColumn.bind(this);
    }

    /**
     * Grab a list of parties and poll results from the backend when this component loads.
     *
     * @return void
     */
    public componentDidMount() {
        Api.get("party.php", response => {
            this.setState({
                parties: response as Types.Party[]
            });
        }, errors => this.setState({ errors }));

        Api.get("partyvotepoll.php", response => {
            this.setState({
                polls: response as Types.PartyVotePoll[]
            });
        }, errors => this.setState({ errors }));
    }

    /**
     * Renders a table column representing a pollster.
     *
     * @param {string} pollster - the pollster to display.
     *
     * @returns {JSX.Element}
     */
    public renderPollsterColumn(pollster: string): JSX.Element {
        return (
            <span style={{ fontSize: '20px' }}>
                {pollster}
            </span>
        );
    }

    /**
     * Renders a table column representing a poll's polling period.
     *
     * @param {PartyVotePoll} result - the result to render the polling period.
     *
     * @returns {JSX.Element}
     */
    public renderPollingPeriodColumn(result: Types.PartyVotePoll): JSX.Element {
        const pollingPeriod = Utilities.formatDate(result.startDate) + " - " + Utilities.formatDate(result.endDate);
        return (
            <span style={{ fontSize: '20px' }}>
                {pollingPeriod}
            </span>
        )
    }

    /**
     * Renders a table column representing a party vote result.
     *
     * @param {PartyVotePollResult} result - the result to render.
     *
     * @returns {JSX.Element}
     */
    public renderResultColumn(result: Types.PartyVotePollResult): JSX.Element {
        const formatted = this.state.showSeats ? result.parliamentarySeats : parseFloat(String(result.result)).toFixed(1);
        return (
            <b style={{ fontSize: '20px', color: result.party.colour }}>
                {formatted}
            </b>
        );
    }

    /**
     * Renders the poll details component when a table row is expanded.
     *
     * @param row - the table row which was expanded.
     *
     * @returns {JSX.Element}
     */
    public renderExpandedRow(row: any): JSX.Element {
        return (
            <PartyVotePollDetails poll={row.record} />
        );
    }

    /**
     * Updates the state when the result preference is changed.
     *
     * @returns void
     */
    public onResultPreferenceToggle() {
        this.setState({
            showSeats: !this.state.showSeats
        });
    }

    /**
     * Renders the result preference radio buttons.
     *
     * @returns {JSX.Element}
     */
    public renderResultPreference(): JSX.Element {
        return (
            <div style={{ float: 'right', marginBottom: '10px' }}>
                <Radio.Group
                    value={this.state.showSeats ? "seats" : "vote"}
                    onChange={this.onResultPreferenceToggle}
                >
                    <Radio value={"vote"}>Party Vote</Radio>
                    <Radio value={"seats"}>Parliamentary Seats</Radio>
                </Radio.Group>
            </div>
        );
    }

    /**
     * Returns the rows of the table of polls.
     *
     * @returns {any[]}
     */
    public getTableRows(): any[] {
        return this.state.polls
            .map((poll: Types.PartyVotePoll) => {
                let row = {
                    key: (poll.pollster + poll.startDate),
                    pollster: poll.pollster,
                    record: poll,
                };

                poll.results.forEach((result: Types.PartyVotePollResult) => {
                    row[result.party.code] = result;
                });

                return row;
            });
    }

    /**
     * Returns an alert suggesting the user to rotate their device.
     *
     * @returns {JSX.Element}
     */
    private renderRotationSuggestion(): JSX.Element {
        return (
            <div className={"rotationSuggestion"}>
                <Alert
                    message={"Small Screen Resolution"}
                    description={"We recommended rotating your device to view this page."}
                    type={"info"}
                    showIcon={true}
                />
            </div>
        );
    }

    /**
     * Renders the component.
     *
     * @returns {any}
     */
    public render() {
        const errors = this.state.errors;
        if (errors.length > 0) {
            return <Error key={errors.length} error={errors.join("\n")} />;
        }

        if (this.state.parties.length > 0 && this.state.polls.length > 0) {
            return (
                <div>
                    {this.renderRotationSuggestion()}

                    {this.renderResultPreference()}

                    <Table
                        dataSource={this.getTableRows()}
                        size={"middle"}
                        expandedRowRender={this.renderExpandedRow}
                        pagination={{ pageSize: this.props.pollsToShow }}
                    >
                        <Table.Column
                            title={"Pollster"}
                            dataIndex={"pollster"}
                            key={"pollster"}
                            render={this.renderPollsterColumn}
                        />

                        <Table.Column
                            title={"Polling Period"}
                            dataIndex={"record"}
                            key={"pollingPeriod"}
                            render={this.renderPollingPeriodColumn}
                        />

                        {this.state.parties.map((party: Types.Party) => {
                            return <Table.Column
                                title={party.code}
                                dataIndex={party.code}
                                key={party.code}
                                render={this.renderResultColumn}
                            />
                        })}
                    </Table>

                    <PartyVotePollingGraphs
                        polls={this.state.polls}
                        parties={this.state.parties}
                        seats={this.state.showSeats}
                    />
                </div>
            );
        }

        return <LoadingIcon />
    }
}