import * as React from 'react';
import * as Types from "../../model/Types";
import {PollingTrendGraph} from "../graph/PollingTrendGraph";
import {LeftHalf, RightHalf} from '../general/Styles';

interface PartyVotePollingGraphsProps {
    polls: Types.PartyVotePoll[];
    parties: Types.Party[];
    seats: boolean;
}

export class PartyVotePollingGraphs extends React.Component<PartyVotePollingGraphsProps> {

    constructor(props: PartyVotePollingGraphsProps) {
        super(props);
    }

    /**
     * Renders the component.
     *
     * @returns {any}
     */
    public render() {
        const polls = this.props.polls.slice().reverse();
        const parties = this.props.parties.slice();

        return (
            <div>
                <LeftHalf>
                    <PollingTrendGraph
                        polls={polls}
                        parties={parties}
                        seats={this.props.seats}
                        major={true}
                    />
                </LeftHalf>
                <RightHalf>
                    <PollingTrendGraph
                        polls={polls}
                        parties={parties}
                        seats={this.props.seats}
                        major={false}
                    />
                </RightHalf>
            </div>
        );
    }
}