import * as Types from './Types';
import * as Constants from './Constants';

/**
 * Formats the given date string.
 * eg. 18/12/2018 => 18 Dec 2018
 *
 * @param {string | number} dateInput - the input to format.
 *
 * @returns {string}
 */
export function formatDate(dateInput: string | number): string {
    // If it's a unix timestamp, it must be cast to a number, otherwise the date object will try to read it as a date string!
    const input: string | number = isNaN(Number(dateInput)) ? dateInput: Number(dateInput);

    let date: Date = new Date(input);
    let configuration = {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
    };

    return date.toLocaleDateString("en-GB", configuration);
}

/**
 * Returns the unix timestamp of the given date string.
 *
 * @param {string} dateString - the YYYY-MM-DD date string to format.
 *
 * @returns {number}
 */
export function getUnixTimestamp(dateString: string): number {
    let date: Date = new Date(dateString);
    return date.getTime();
}

/**
 * Returns the current government's result totals for a given poll's results.
 *
 * @param {PartyVotePollResult[]} results - the results of the poll.
 * @param {boolean} seats - if true, each result's seat result will be summed.
 *
 * @returns {string | number}
 */
export function calculateGovernmentTotal(results: Types.PartyVotePollResult[], seats: boolean): string | number {
    let total: number = 0;

    for (let result of results) {
        if (Constants.GOVERNMENT.indexOf(result.party.code) > -1) {
            total += Number(seats ? result.parliamentarySeats : result.result);
        }
    }

    return seats ? total : total.toFixed(1);
}

/**
 * Converts a singular string into a plural.
 * This accounts for words which end with the letter "s".
 *
 * @param {string} text - the singular word to pluralise.
 *
 * @returns {string}
 */
export function plural(text: string): string {
    let lastChar: string = text[text.length - 1];
    return lastChar !== "s" ? text + "'s" : text + "'";
}

/**
 * Calculates the total number of parliamentary seats won by the given set of parties.
 *
 * @param {string[]} parties - an array of party codes.
 * @param {PartyVotePollResult[]} results - an array of poll results.
 *
 * @returns {number}
 */
export function calculateSeatTotal(parties: string[], results: Types.PartyVotePollResult[]): number {
    const initial: number = 0;
    return results.reduce((total, result) => {
        return total + (parties.indexOf(result.party.code) > -1 ? Number(result.parliamentarySeats) : 0);
    }, initial);
}

/**
 * Returns the order in which parties should be displayed on a parliament graphic.
 *
 * @param {PartyVotePollResult[]} results - the results to base the order on.
 *
 * @returns {string[]}
 */
export function getPartyOrder(results: Types.PartyVotePollResult[]): string[] {
    const total: number = calculateSeatTotal(Constants.NATIONAL_COALITION, results);
    if (total > 60) {
        return Constants.PARTY_ORDER_REVERSED;
    } else {
        return Constants.PARTY_ORDER;
    }
}

/**
 * Shuffles an array randomly.
 *
 * @param {any[]} array - the array to shuffle.
 *
 * @returns {any[]}
 */
export function shuffle(array: any[]): any[] {
    return array
        .map((a: any) => ({
            sort: Math.random(),
            value: a,
        }))
        .sort((a: any, b: any) => a.sort - b.sort)
        .map((a: any) => a.value);
}

/**
 * Returns whether or not an object is empty.
 *
 * @param object - the object to check.
 *
 * @returns {boolean}
 */
export function isEmpty(object: any): boolean {
    return Object.keys(object).length === 0;
}