/**
 * The political axes measured on the political values test.
 *
 * @type {string[]}
 */
export const POLITICAL_AXES: string[] = ["social", "economic", "nation", "state"];

/**
 * The number of questions asked in the test.
 *
 * @type {number}
 */
export const QUESTION_COUNT: number = 50;

/**
 * The titles for positions along each measured political axis.
 *
 * @type {any}
 */
export const TITLES = {
    social: ["Progressive", "Liberal", "Centrist", "Conservative", "Traditional Conservative"],
    economic: ["Free Market Capitalist", "Capitalist", "Mixed Economy", "Social", "Socialist"],
    nation: ["Internationalist", "Globalist", "Balanced", "Patriot", "Nationalist"],
    state: ["Libertarian", "Liberal", "Moderate", "Statist", "Authoritarian"],
};

/**
 * The icons to show on the left and right sides of the political axis graphic.
 *
 * @type {any}
 */
export const ICONS = {
    social: {
        left: "team",
        right: "hourglass",
    },
    economic: {
        left: "dollar",
        right: "share-alt",
    },
    nation: {
        left: "global",
        right: "flag",
    },
    state: {
        left: "fire",
        right: "bank",
    }
};

/**
 * The primary and secondary colours to make up the bar graphics for each axis.
 *
 * @type {any}
 */
export const COLOURS = {
    social: {
        primary: "#237804",
        secondary: "#135200",
    },
    economic: {
        primary: "#096dd9",
        secondary: "#003a8c",
    },
    nation: {
        primary: "#d4380d",
        secondary: "#871400",
    },
    state: {
        primary: "#d4b106",
        secondary: "#876800",
    }
};

/**
 * The comparison descriptors for each axis.
 *
 * @type {any}
 */
export const COMPARISON_DESCRIPTORS = {
    social: {
        lowLess: "less socially conservative",
        lowMore: "more socially conservative",
        highLess: "less socially progressive",
        highMore: "more socially progressive",
        same: "Exactly average on social issues",
    },
    economic: {
        lowLess: "less socialist",
        lowMore: "more socialist",
        highLess: "less capitalist",
        highMore: "more capitalist",
        same: "Exactly average on the economy",
    },
    nation: {
        lowLess: "less nationalist",
        lowMore: "more nationalist",
        highLess: "less globalist",
        highMore: "more globalist",
        same: "Exactly average views on international issues",
    },
    state: {
        lowLess: "less authoritarian",
        lowMore: "more authoritarian",
        highLess: "less libertarian",
        highMore: "more libertarian",
        same: "Exactly average views on size and role of government",
    }
};


