export const ALIGNMENTS: any[] = [
    {
        code: "NAT",
        ideologies: "Neo-Liberalism / Conservatism",
        axes: {
            social: 0.45,
            economic: 0.7,
            nation: 0.55,
            state: 0.5,
        }
    },
    {
        code: "LAB",
        ideologies: "Social Democracy",
        axes: {
            social: 0.7,
            economic: 0.4,
            nation: 0.7,
            state: 0.35,
        }
    },
    {
        code: "GRN",
        ideologies: "Progressivism / Environmentalism",
        axes: {
            social: 0.85,
            economic: 0.35,
            nation: 0.8,
            state: 0.45,
        }
    },
    {
        code: "NZF",
        ideologies: "Nationalism / Populism / Conservatism",
        axes: {
            social: 0.4,
            economic: 0.4,
            nation: 0.2,
            state: 0.3,
        }
    },
    {
        code: "ACT",
        ideologies: "Classical Liberalism / Libertarianism",
        axes: {
            social: 0.5,
            economic: 0.8,
            nation: 0.6,
            state: 0.75,
        }
    },
    {
        code: "MRI",
        ideologies: "Indigenous Rights",
        axes: {
            social: 0.75,
            economic: 0.4,
            nation: 0.3,
            state: 0.3,
        }
    },
    {
        code: "TOP",
        ideologies: "Radical Centrism",
        axes: {
            social: 0.7,
            economic: 0.55,
            nation: 0.6,
            state: 0.55,
        }
    },
    {
        code: "NCP",
        ideologies: "Social Conservatism",
        axes: {
            social: 0.2,
            economic: 0.75,
            nation: 0.2,
            state: 0.6,
        }
    },
    {
        code: "SOC",
        ideologies: "Economic Democracy / Social Credit",
        axes: {
            social: 0.6,
            economic: 0.45,
            nation: 0.4,
            state: 0.65,
        }
    },
    {
        code: "SUS",
        ideologies: "Centrism / Environmentalism",
        axes: {
            social: 0.55,
            economic: 0.55,
            nation: 0.65,
            state: 0.45,
        }
    },
    {
        code: "OUT",
        ideologies: "Environmentalism",
        axes: {
            social: 0.4,
            economic: 0.5,
            nation: 0.4,
            state: 0.4,
        }
    },
    {
        code: "MNA",
        ideologies: "Tino Rangatiratanga",
        axes: {
            social: 0.6,
            economic: 0.25,
            nation: 0.3,
            state: 0.3
        }
    },
];