import * as React from "react";
import * as Types from "../../model/Types";
import * as Ideologies from "../../model/test/Ideologies";
import * as Api from "../../model/Api";
import {PoliticalAxisResult} from "./results/PoliticalAxisResult";
import {MatchingPartiesResult} from "./results/MatchingPartiesResult";
import {ResultsPermalink} from "./results/ResultsPermalink";
import "../../style/test/ValuesTestResults.scss";
import {Modal} from "antd";
import {ValuesTestDemographics} from "./ValuesTestDemographics";
import {CompareResultPopup} from "./results/CompareResultPopup";

interface ValuesTestResultsProps {
    social: number;
    economic: number;
    nation: number;
    state: number;
    answers: Types.QuestionAnswer[];
    fromLink: boolean;
    identifier: string | undefined;
}

interface ValuesTestResultsState {
    identifier: string | undefined;
    answeringDemographics: boolean;
    comparingResult: boolean;
    errors: string[];
}

export class ValuesTestResults extends React.Component<ValuesTestResultsProps, ValuesTestResultsState> {

    private ref: React.RefObject<ValuesTestDemographics>;

    constructor(props: ValuesTestResultsProps) {
        super(props);

        this.state = {
            identifier: undefined,
            answeringDemographics: false,
            comparingResult: false,
            errors: [],
        };

        this.ref = React.createRef();

        this.toggleDemographicsModal = this.toggleDemographicsModal.bind(this);
        this.onModalOk = this.onModalOk.bind(this);
        this.toggleComparingResultsModal = this.toggleComparingResultsModal.bind(this);
    }

    /**
     * Saves the results by making a POST request to the backend and saving the resulting identifier.
     * This will only do so if this is a new result being displayed right after the test.
     *
     * @returns void
     */
    public componentDidMount() {
        if (!this.props.fromLink && this.props.answers.length > 0 && !this.isLocalhost()) {
            const body = {
                social: this.props.social,
                economic: this.props.economic,
                nation: this.props.nation,
                state: this.props.state
            };

            Api.post("submitresult.php", body, response => {
                const identifier: string = response.identifier;
                this.setState({ identifier });
                this.submitAnswers(identifier);
                // this.toggleDemographicsModal();
            }, errors => this.setState({ errors }));
        }
    }

    /**
     * Returns the unique identifier for this test result.
     *
     * @returns {string | undefined}
     */
    private getIdentifier(): string | undefined {
        if (this.props.fromLink) {
            return this.props.identifier;
        } else {
            return this.state.identifier;
        }
    }

    /**
     * Returns whether or not this is running locally.
     *
     * @returns {boolean}
     */
    private isLocalhost(): boolean {
        return ["localhost", "127.0.0.1", ""].includes(window.location.hostname);
    }

    /**
     * Saves the answers to individual questions by making a POST request to the backend.
     *
     * @param {string} identifier - the identifier of the overall test results.
     *
     * @returns void
     */
    private submitAnswers(identifier: string) {
        let body = {
            identifier,
            answers: this.props.answers,
        };

        Api.post("submitanswers.php", body, response => {
            // this block has been intentionally left empty.
        }, errors => this.setState({ errors }));
    }

    /**
     * Returns the ideology closest to the results on the political axes.
     *
     * @returns {Ideology}
     */
    public getClosestIdeology(): Types.Ideology {
       const ideologies: Types.Ideology[] = Ideologies.DEFINITIONS;

       let current: Types.Ideology | undefined;
       let distance: number = 0;

       for (let ideology of ideologies) {
           let dist: number = 0;

           dist += Math.abs(ideology.axes.social - this.props.social);
           dist += Math.abs(ideology.axes.economic - this.props.economic);
           dist += Math.abs(ideology.axes.nation - this.props.nation);
           dist += Math.abs(ideology.axes.state - this.props.state);

           if (current === undefined || dist < distance) {
               current = ideology;
               distance = dist;
           }
       }

       return current || ideologies[0];
    }

    /**
     * Renders the name and info about the closest ideology for these results.
     *
     * @returns {JSX.Element}
     */
    public renderIdeology(): JSX.Element {
        const ideology: Types.Ideology = this.getClosestIdeology();

        return (
            <div className={"ideologyWrapper"}>
                <span className={"ideologyName"}>
                    {ideology.name}
                </span>

                <span className={"ideologyDesc"}>
                    {ideology.desc}
                </span>
            </div>
        );
    }

    /**
     * This function is called when the demographic modal is closed.
     *
     * @returns void
     */
    private onModalOk() {
        this.toggleDemographicsModal();

        if (this.ref.current !== null) {
            this.ref.current.onSubmit();
        }
    }

    /**
     * This function toggles the visibility of the demographics modal.
     *
     * @returns void
     */
    private toggleDemographicsModal() {
        this.setState({
            answeringDemographics: !this.state.answeringDemographics
        });
    }

    /**
     * This function toggles the visibility of the viewing answers modal.
     *
     * @returns void
     */
    private toggleComparingResultsModal() {
        this.setState({
            comparingResult: !this.state.comparingResult
        });
    }

    /**
     * Renders the clickable text to view individual answers.
     *
     * @returns {JSX.Element}
     */
    /*
    private renderCompareText(): JSX.Element {
        return (
            <span className={"compareResult"}>
                <span className={"compareResultText"} onClick={this.toggleComparingResultsModal}>
                    How does my result compare?
                </span>
            </span>
        );
    }
    */

    /**
     * Renders the component.
     *
     * @returns {any}
     */
    public render() {
        const identifier = this.getIdentifier();

        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div className={"adjustingCenteredContent"}>
                    {this.renderIdeology()}

                    <PoliticalAxisResult axis={"Social"} value={this.props.social} /><br />
                    <PoliticalAxisResult axis={"Economic"} value={this.props.economic} /><br />
                    <PoliticalAxisResult axis={"Nation"} value={this.props.nation} /><br />
                    <PoliticalAxisResult axis={"State"} value={this.props.state} />

                    <MatchingPartiesResult
                        social={this.props.social}
                        economic={this.props.economic}
                        nation={this.props.nation}
                        state={this.props.state}
                    />

                    {(this.state.identifier && !this.props.fromLink) && <ResultsPermalink identifier={this.state.identifier} />}

                    {(this.state.identifier && !this.props.fromLink) && <Modal
                        title={"Questions About You"}
                        visible={this.state.answeringDemographics}
                        centered={true}
                        okText={"View My Results"}
                        cancelText={"Skip"}
                        onOk={this.onModalOk}
                        onCancel={this.toggleDemographicsModal}
                    >
                        <ValuesTestDemographics
                            identifier={this.state.identifier}
                            ref={this.ref}
                        />
                    </Modal>}

                    {identifier && <Modal
                        title={"Compare Your Result"}
                        visible={this.state.comparingResult}
                        centered={true}
                        footer={null}
                        onCancel={this.toggleComparingResultsModal}
                    >
                        <CompareResultPopup
                            result={{
                                identifier,
                                time: "n/a",
                                social: this.props.social,
                                economic: this.props.economic,
                                nation: this.props.nation,
                                state: this.props.state
                            }}
                        />
                    </Modal>}
                </div>
            </div>
        );
    }
}