import * as Constants from './Constants';
import axios from 'axios';
import {AxiosResponse} from 'axios';

/**
 * Makes a GET request to grab data from the backend.
 *
 * @param {string} endpoint - the endpoint to get data from.
 * @param {(response: any) => void} onSuccess - a callback function to call upon success.
 * @param {(response: any) => void} onError - a callback function to call upon an error occurring.
 * @param {string[]} parameters - any parameters to append to the request URL, defaults to an empty array.
 *
 * @returns void
 */
export function get(endpoint: string, onSuccess: (response: any) => void, onError: (response: any) => void, parameters: string[] = []) {
    let url = Constants.BACKEND + "get/" + endpoint + "?authentication=" + process.env.REACT_APP_AUTH;

    if (parameters.length > 0) {
        url += "&" + parameters.join("&");
    }

    axios.get(url).then((response: AxiosResponse) => {
        if (response.data.status === "SUCCESS") {
            let content = response.data.content;
            if (content !== undefined) {
                onSuccess(content);
            } else {
                onError(["The response content is empty!"]);
            }
        } else {
            onError(response.data.messages);
        }
    });
}

/**
 * Makes a POST request to send data to the backend.
 *
 * @param {string} endpoint - the endpoint to POST data to.
 * @param {any} body - the body of the request.
 * @param {(response: any) => void} onSuccess - a callback function to call upon success.
 * @param {(response: any) => void} onError - a callback function to call upon an error occurring.
 *
 * @returns void
 */
export function post(endpoint: string, body: any, onSuccess: (response: any) => void, onError: (response: any) => void) {
    let url = Constants.BACKEND + "post/" + endpoint + "?authentication=" + process.env.REACT_APP_AUTH;

    axios.post(url, body).then((response: AxiosResponse) => {
        if (response.data.status === "SUCCESS") {
            let content = response.data.content;
            if (content !== undefined) {
                onSuccess(content);
            } else {
                onError(["The response content is empty!"]);
            }
        }  else {
            onError(response.data.messages);
        }
    });
}
