import * as React from "react";
import * as Types from "../../model/Types";
import {Link} from "react-router-dom";
import {Icon, Modal} from 'antd';
import "../../style/menu/NavigationMenu.scss";

interface NavigationMenuProps {
    pages: Types.Page[];
}

interface NavigationMenuState {
    menuVisible: boolean;
}

export class NavigationMenu extends React.Component<NavigationMenuProps, NavigationMenuState> {

    constructor(props: NavigationMenuProps) {
        super(props);

        this.state = {
            menuVisible: false
        };

        this.toggleMobileMenuVisibility = this.toggleMobileMenuVisibility.bind(this);
    }

    /**
     * Toggles the visibility of the mobile navigation menu.
     *
     * @returns void
     */
    private toggleMobileMenuVisibility() {
        this.setState({
            menuVisible: !this.state.menuVisible,
        });
    }

    /**
     * Renders a menu item for a given page on the navigation menu.
     *
     * @param {Page} page - the page to render a menu item for.
     *
     * @returns {JSX.Element}
     */
    private renderMenuItem(page: Types.Page): JSX.Element {
        return (
            <li className={"menuItem"} key={page.path}>
                <Link
                    to={"/" + page.path}
                    className={"link"}
                    style={{ textDecoration: 'none' }}
                >
                    <Icon type={page.icon} />
                    {page.name}
                </Link>
            </li>
        );
    }

    /**
     * Renders this component.
     *
     * @returns {any}
     */
    public render() {
        return (
            <header className={"navigationMenu"}>
                <Link to="/">
                    <img
                        className={"bannerImage"}
                        src={"/images/banner.png"}
                    />
                </Link>

                <ul className={"menu"}>
                    {this.props.pages.map((page: Types.Page) => this.renderMenuItem(page))}
                </ul>

                <div className={"mobileMenu"}>
                    <Icon
                        type={"menu-unfold"}
                        className={"mobileIcon"}
                        onClick={this.toggleMobileMenuVisibility}
                    />
                </div>

                <Modal
                    title={null}
                    footer={null}
                    closable={false}
                    visible={this.state.menuVisible}
                    onCancel={this.toggleMobileMenuVisibility}
                    className={"mobileNavigationModal"}
                >
                    {this.props.pages.map((page: Types.Page) => {
                        return (
                            <div key={page.path} className={"mobileMenuItem"}>
                                <Link
                                    to={"/" + page.path}
                                    className={"mobileMenuLink"}
                                    onClick={this.toggleMobileMenuVisibility}
                                >
                                    <Icon type={page.icon} />
                                    {page.name}
                                </Link>
                            </div>
                        );
                    })}
                </Modal>
            </header>
        );
    }
}