import * as React from 'react';
import * as Types from '../../model/Types';
import * as Utilities from '../../model/Utilities';
import * as Constants from '../../model/Constants';
import {PotentialCoalition} from './PotentialCoalition';
import {HeaderText, Left60, Right40} from "../general/Styles";

interface PartyVotePollDetailsProps {
    poll: Types.PartyVotePoll;
}

export class PartyVotePollDetails extends React.Component<PartyVotePollDetailsProps> {

    constructor(props: PartyVotePollDetailsProps) {
        super(props);
    }

    /**
     * Returns the number of seats in the largest potential coalition for this poll.
     *
     * @returns {number}
     */
    public getLargestCoalitionSeatCount(): number {
        let largest: number = 0;
        for (let coalition of this.props.poll.coalitions) {
            if (coalition.totalSeats > largest) {
                largest = coalition.totalSeats;
            }
        }

        return largest;
    }

    /**
     * Renders bold coloured text displaying the difference in party vote since the election.
     *
     * @param {number | string} partyVote - the government's party vote in this poll.
     *
     * @returns {JSX.Element}
     */
    public renderPartyVoteDifference(partyVote: number | string): JSX.Element {
        const partyVoteDiff: number = (Number(partyVote) - Constants.GOVERNMENT_TOTAL_PARTY_VOTE);
        return (
            <b style={{ color: (partyVoteDiff >= 0 ? "green" : "red") }}>
                {(partyVoteDiff > 0 ? "+" : "") + partyVoteDiff.toFixed(2)}%
            </b>
        );
    }

    /**
     * Renders bold coloured text displaying the difference in seats since the election.
     *
     * @param {number | string} seats - the government's seat total in this poll.
     *
     * @returns {JSX.Element}
     */
    public renderSeatDifference(seats: number | string): JSX.Element {
        const seatDiff: number = (Number(seats) - Constants.GOVERNMENT_TOTAL_SEATS);
        return (
            <b style={{ color: (seatDiff >= 0 ? "green" : "red") }}>
                {(seatDiff > 0 ? "+" : "") + seatDiff} seats
            </b>
        );
    }

    /**
     * Renders the component.
     *
     * @returns {any}
     */
    public render() {
        const poll: Types.PartyVotePoll = this.props.poll;
        const partyVote: number | string = Utilities.calculateGovernmentTotal(poll.results, false);
        const totalSeats: number | string = Utilities.calculateGovernmentTotal(poll.results, true);
        const largestSeatCount: number = this.getLargestCoalitionSeatCount();

        return (
            <div>
                <Left60>
                    <HeaderText>Potential Coalitions</HeaderText>
                    {poll.coalitions.map((coalition: Types.PotentialCoalition) => {
                        return <PotentialCoalition
                            coalition={coalition}
                            largestCoalitionSeats={largestSeatCount}
                            key={coalition.totalSeats + coalition.members.length}
                        />
                    })}
                </Left60>

                <Right40 style={{ fontSize: '18px' }}>
                    <HeaderText>Poll Statistics</HeaderText>
                    <br/>
                    At the time this poll was conducted, the <b>current government</b> was polling at <b>{partyVote}%</b>.
                    <br/>
                    That means they'd get a total of <b>{totalSeats}</b> seats in Parliament.
                    <br/>
                    That's a change of {this.renderPartyVoteDifference(partyVote)} or {this.renderSeatDifference(totalSeats)} from the last general election.
                </Right40>
            </div>
        );
    }
}