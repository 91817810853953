import * as React from 'react';
import {ValuesTestBase} from "../components/test/ValuesTestBase";
import { Alert } from "antd";

export class PoliticalValuesTest extends React.Component {

    public render() {
        return (
            <div>
                <Alert
                    message={"Politikiwi has been retired"}
                    description={"This project is no longer actively maintained. The questions in the values test and the underlying ideology and party position reference points have not been updated since 2019. There are questions in the test I would not include if I developed it again today. While the test can still be useful in understanding your political views and potential party preferences, I would recommend also using other, more up-to-date New Zealand political resources to compare and understand your results. Thank you."}
                    type={"warning"}
                    showIcon={true}
                />

                <ValuesTestBase identifier={undefined} />
            </div>
        );
    }
}