import * as React from "react";
import * as Types from "../../../model/Types";
import * as Api from "../../../model/Api";
import {Icon} from "antd";
import {Error} from "../../general/Error";
import {ValuesTestResults} from "../ValuesTestResults";
import {CenteredContent} from "../../general/Styles";
import {LoadingIcon} from "../../general/LoadingIcon";

interface ExistingResultsWrapperProps {
    identifier: string;
}

interface ExistingResultsWrapperState {
    fetching: boolean;
    result: Types.TestResult | undefined;
    errors: string[];
}

export class ExistingResultsWrapper extends React.Component<ExistingResultsWrapperProps, ExistingResultsWrapperState> {

    constructor(props: ExistingResultsWrapperProps) {
        super(props);

        this.state = {
            fetching: true,
            result: undefined,
            errors: [],
        };
    }

    /**
     * Makes a GET request to try fetch the test result from the backend.
     *
     * @returns void
     */
    public componentDidMount() {
        Api.get("testresult.php", response => {
            const results: Types.TestResult[] = response as Types.TestResult[];

            this.setState({
                fetching: false,
                result: results.length > 0 ? results[0] : undefined,
            });
        }, errors => this.setState({ fetching: false, errors }), [
            "identifier=" + this.props.identifier
        ]);
    }

    /**
     * Returns a suggested reason why the test identifier may be invalid.
     *
     * @returns {string}
     */
    public suggestReason(): string {
        const identifier: string = this.props.identifier;

        if (identifier.length > 10) {
            return "The test identifier you specified is too long!";
        }

        if (identifier.length < 10) {
            return "The test identifier you specified is too short!";
        }

        if (!(identifier.match(/^[A-Za-z0-9]+$/))) {
            return "The test identifier you specified contains invalid characters!";
        }

        return "The test identifier you specified is invalid!";
    }

    /**
     * Returns the page content to be rendered if no results can be found.
     *
     * @returns {JSX.Element}
     */
    public renderResultsNotFound(): JSX.Element {
        return (
            <CenteredContent style={{ height: '250px' }}>
                <Icon
                    type={"exclamation-circle"}
                    style={{ fontSize: '50px' }}
                />

                <span style={{ fontSize: '30px', padding: '10px 10px 10px 20px'}}>
                    {this.suggestReason()}
                </span>
            </CenteredContent>
        );
    }

    /**
     * Renders the component.
     *
     * @returns {any}
     */
    public render() {
        const errors = this.state.errors;
        if (errors.length > 0) {
            return <Error key={errors.length} error={errors.join("\n")} />
        }

        if (!this.state.fetching) {
            if (this.state.result !== undefined) {
                return <ValuesTestResults
                    social={this.state.result.social}
                    economic={this.state.result.economic}
                    nation={this.state.result.nation}
                    state={this.state.result.state}
                    answers={[]}
                    fromLink={true}
                    identifier={this.props.identifier}
                />
            } else {
                return this.renderResultsNotFound();
            }
        }

        return <LoadingIcon />
    }
}