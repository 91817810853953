import * as React from "react";
import "../../style/general/LoadingIcon.scss";

interface LoadingIconState {
    currentColour: number;
}

export class LoadingIcon extends React.Component<{}, LoadingIconState> {

    private interval: any;

    constructor(props: any) {
        super(props);

        this.state = {
            currentColour: 0
        };
    }

    /**
     * Initiates a repeating timer to change the colour of the loading icon.
     *
     * @returns void
     */
    public componentDidMount() {
        this.interval = setInterval(() => {
            const colourIndex: number = this.state.currentColour;
            const maxIndex: number = this.getColours().length - 1;

            this.setState({
                currentColour: ((colourIndex + 1) > maxIndex ? 0 : colourIndex + 1)
            });
        }, 100);
    }

    /**
     * Clears the repeating timer when the component is unmounted.
     *
     * @returns void
     */
    public componentWillUnmount() {
        clearInterval(this.interval);
    }

    /**
     * Returns the RGB colours the loading icon will cycle through.
     *
     * This is really static, but having a loading icon make a request would
     * mean the loading icon would need a loading icon itself!
     *
     * @returns {string[]}
     */
    private getColours(): string[] {
        return [
            "49, 105, 247",
            "247, 49, 49",
            "35, 191, 21",
            "71, 71, 71",
            "255, 209, 0",
        ];
    }

    /**
     * Returns the current colour with the given transparency value.
     *
     * @param {number} alpha - transparency value between 0 and 1.
     *
     * @returns {string}
     */
    private getCurrentColour(alpha: number): string {
        return "rgba(" + this.getColours()[this.state.currentColour] + ", " + alpha + ")";
    }

    /**
     * Renders the circle element.
     *
     * @returns {JSX.Element}
     */
    private renderCircle(): JSX.Element {
        return (
            <svg
                xmlns={"http://www.w3.org/2000/svg"}
                version={"1.1"}
                width={"150"}
                height={"150"}
            >
                <circle
                    cx={"75"}
                    cy={"75"}
                    r={"70"}
                    stroke={this.getCurrentColour(1)}
                    strokeWidth={"8"}
                    fill={this.getCurrentColour(0.6)}
                />
            </svg>
        );
    }

    /**
     * Renders the component.
     *
     * @returns {any}
     */
    public render() {
        return (
            <div className={"centeredLoading"}>
                <div className={"loadingParent"}>
                    <div className={"loadingCircle"}>
                        {this.renderCircle()}
                    </div>

                    <div className={"loadingImage"}>
                        <img
                            src={"images/iconWhite.png"}
                            width={"120px"}
                            height={"120px"}
                        />
                    </div>
                </div>
            </div>
        );
    }
}