import * as React from 'react';
import {PartyVotePollsTable} from "../components/polling/PartyVotePollsTable";
import {PageHeaderText, HeaderWrapper, Text} from 'src/components/general/Styles';
import { Alert } from "antd";

export class Polling extends React.Component {

    public render() {
        return (
            <div>
                <HeaderWrapper>
                    <Alert
                        message={"Politikiwi has been retired"}
                        description={"This project is no longer actively maintained. These polls may be months out of date, and page elements may be broken."}
                        type={"warning"}
                        showIcon={true}
                    />
                    <br/>
                    <PageHeaderText>Opinion Polling for the 2023 General Election</PageHeaderText>
                    <br/>
                    <Text>
                        This page shows all political party vote polls conducted for the upcoming general election.
                        You can click on a poll in the table to view more information and potential coalitions based on those results.
                    </Text>
                </HeaderWrapper>

                <PartyVotePollsTable pollsToShow={5} />
            </div>
        );
    }
}