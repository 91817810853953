import * as React from 'react';
import {HeaderWrapper, PageHeaderText, Text} from 'src/components/general/Styles';
import {AggregatePolling} from "../components/polling/AggregatePolling";
import { Alert } from "antd";

export class Home extends React.Component {

    public render() {
        return (
            <div>
                <HeaderWrapper>
                    <Alert
                        message={"Politikiwi has been retired"}
                        description={"This project is no longer actively maintained. The polls used to generate this aggregate poll may be months out of date."}
                        type={"warning"}
                        showIcon={true}
                    />
                    <br/>
                    <PageHeaderText>Politikiwi's Poll of Polls</PageHeaderText>
                    <br/>
                    <Text>
                        Our custom poll of polls predicts the outcome of an election held today, based
                        upon an aggregate of the most recently conducted polls.
                        It assumes that all parties win the same electorates that they did in the 2020 election.
                    </Text>
                </HeaderWrapper>

                <AggregatePolling />
            </div>
        );
    }
}