import * as React from "react";
import * as Types from "../../model/Types";
import {Tooltip} from "antd";

interface PotentialCoalitionProps {
    coalition: Types.PotentialCoalition;
    largestCoalitionSeats: number;
}

export class PotentialCoalition extends React.Component<PotentialCoalitionProps> {

    constructor(props: PotentialCoalitionProps) {
        super(props);
    }

    /**
     * Returns the formatted list of parties in this coalition.
     *
     * @returns {string}
     */
    public getText() {
        let text: string = "";
        const members = this.props.coalition.members;

        for (let i = 0; i < members.length; i++) {
            text += members[i].party.name;
            if (i !== (members.length - 1)) {
                text += " and the ";
            }
        }

        text += (" (" + this.props.coalition.totalSeats + " seats)");
        return text;
    }

    /**
     * Renders the component.
     *
     * @returns {any}
     */
    public render() {
        const coalition = this.props.coalition;
        const text = this.getText();

        return (
            <div>
                <span>{text}</span>
                <br/>
                <div style={{ display: 'inline-flex', width: '75%', height: '25px' }}>
                    {coalition.members.map((member: Types.PotentialCoalitionMember) => {
                        let width = (member.seats / this.props.largestCoalitionSeats) * 100;
                        return (
                            <Tooltip title={member.party.name + " (" + member.seats + " seats)"} key={member.party.name + member.seats}>
                                <div style={{ display: 'inline-flex', width: width + '%', backgroundColor: member.party.colour }}/>
                            </Tooltip>
                        );
                    })}
                </div>
            </div>
        );
    }
}