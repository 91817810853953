import * as React from "react";
import * as Types from "../../../model/Types";
import * as Api from "../../../model/Api";
import * as Utilities from "../../../model/Utilities";
import {Error} from "../../general/Error";
import {CenteredContent} from "../../general/Styles";
import {ResultCompare} from "./ResultCompare";
import {Skeleton} from "antd";

interface CompareResultPopupProps {
    result: Types.TestResult;
}

interface CompareResultPopupState {
    comparison: Types.TestComparison | undefined;
    errors: string[];
}

export class CompareResultPopup extends React.Component<CompareResultPopupProps, CompareResultPopupState> {

    constructor(props: CompareResultPopupProps) {
        super(props);

        this.state = {
            comparison: undefined,
            errors: []
        };
    }

    /**
     * Fetches the comparison data from the backend.
     *
     * @returns void
     */
    public componentDidMount() {
        Api.get("compareresult.php", response => {
            this.setState({
                comparison: response as Types.TestComparison
            });
        }, errors => this.setState({ errors }), [
            "identifier=" + this.props.result.identifier,
        ]);
    }

    /**
     * Renders the text shown when no demographic information was provided.
     *
     * @returns {JSX.Element}
     */
    private renderNoInfoText(): JSX.Element {
        return (
            <CenteredContent>
                <span style={{ fontSize: '18px' }}>
                    You chose not to provide demographic or voter intention information with this result,
                    so we cannot compare it to the results of similar respondents.
                </span>
            </CenteredContent>
        );
    }

    /**
     * Renders the component.
     *
     * @returns void
     */
    public render() {
        const errors = this.state.errors;
        if (errors.length > 0) {
            return <Error key={errors.length} error={errors.join("\n")} />
        }

        if (this.state.comparison !== undefined) {
            const comparison: Types.TestComparison = this.state.comparison;
            const demographic: Types.DemographicComparison = comparison.demographic as Types.DemographicComparison;

            if (Utilities.isEmpty(demographic)) {
                return this.renderNoInfoText();
            }

            let partyVoteComparison;
            if (!(Utilities.isEmpty(comparison.party))) {
                partyVoteComparison = (comparison.party as Types.PartyComparison);
            }

            let voteIntentionComparison;
            if (!(Utilities.isEmpty(comparison.intention))) {
                voteIntentionComparison = (comparison.intention as Types.PartyComparison);
            }
            
            return <ResultCompare
                result={this.props.result}
                demographic={demographic}
                partyVote={partyVoteComparison}
                intention={voteIntentionComparison}
            />
        }

        return <Skeleton active={true} />
    }
}