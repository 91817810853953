import * as React from "react";
import * as Types from "../../model/Types";
import {Col, InputNumber, Row} from "antd";
import "../../style/calculator/PartyResultRow.scss";

interface PartyResultRowProps {
    result: Types.PartyVotePollResult;
    onPartyVoteChange: (partyCode: string, value: number) => void,
    onElectorateChange: (partyCode: string, value: number) => void
}

export class PartyResultRow extends React.Component<PartyResultRowProps> {

    constructor(props: PartyResultRowProps) {
        super(props);
    }

    /**
     * Renders this component.
     *
     * @returns {any}
     */
    public render() {
        const result: Types.PartyVotePollResult = this.props.result;
        const nameStyle = { color: result.party.colour };

        return (
            <Row style={{ fontSize: '20px' }}>
                <Col span={10}>
                    <b style={nameStyle} className={"fullPartyName"}>
                        {result.party.name}
                    </b>
                    <b style={nameStyle} className={"shortPartyName"}>
                        {result.party.code}
                    </b>
                </Col>

                <Col span={6}>
                    <InputNumber
                        defaultValue={result.result}
                        min={0}
                        max={100}
                        onChange={(value: string | number | undefined) => {
                            if (value !== undefined) {
                                this.props.onPartyVoteChange(result.party.code, Number(value));
                            }
                        }}
                    />
                </Col>

                <Col span={2} />

                <Col span={6}>
                    <InputNumber
                        defaultValue={result.electorates}
                        min={0}
                        max={71}
                        onChange={(value: string | number | undefined) => {
                            if (value !== undefined) {
                                this.props.onElectorateChange(result.party.code, Number(value));
                            }
                        }}
                    />
                </Col>
            </Row>
        )
    }
}