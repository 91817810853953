import * as React from "react";
import * as Types from "../../model/Types";

interface PartyDetailsProps {
    party: Types.Party;
}

export class PartyDetails extends React.Component<PartyDetailsProps> {

    constructor(props: PartyDetailsProps) {
        super(props);
    }

    /**
     * Returns the description of each value displayed in this popover.
     *
     * @returns {string[]}
     */
    public getValueDescriptors(): string[] {
        let descriptors: string[] = [];
        if (this.props.party.deputyLeader === "(co-leadership)") {
            descriptors.push("Co-Leader", "Co-Leader");
        } else {
            descriptors.push("Party Leader", "Deputy Leader");
        }

        descriptors.push("Parliamentary Seats", "2020 Election Result");
        return descriptors;
    }

    /**
     * Returns the values of party information displayed in this popover.
     *
     * @returns {string[]}
     */
    public getValues(): string[] {
        let values: string[] = [];
        const party: Types.Party = this.props.party;

        if (party.deputyLeader === "(co-leadership)") {
            const leaders: string[] = party.leader.split(" and ");
            if (leaders.length !== 2) {
                values.push("(vacant)", "(vacant)");
            } else {
                values.push(...leaders);
            }
        } else {
            values.push(party.leader, party.deputyLeader);
        }

        values.push(this.getCurrentSeats(), (party.lastElectionResult + "%"));
        return values;
    }

    /**
     * Returns a correctly pluralised string of this party's current seat count.
     *
     * @returns {string}
     */
    public getCurrentSeats(): string {
        const seats: number = this.props.party.currentSeats;
        return seats + " seat" + (seats != 1 ? "s" : "");
    }

    /**
     * Renders the component.
     *
     * @returns {any}
     */
    public render() {
        return (
            <div style={{ fontFamily: 'Source Sans Pro', height: '90px' }}>
                <div style={{ float: 'left', width: '150px' }}>
                    {this.getValueDescriptors().map((descriptor: string, index: number) => {
                        return <b key={index}>{descriptor}<br/></b>
                    })}
                </div>
                <div style={{ float: 'right', width: '150px' }}>
                    {this.getValues().map((value: string, index: number) => {
                        return <span key={index}>{value}<br/></span>
                    })}
                </div>
            </div>
        );
    }
}